import React from "react";
import { DatePicker, Form } from "antd";
import styled from "styled-components";
const BaseDateWrapped = styled.div`
  label {
    font-weight: 400;
    font-size: 16px;
    color: #181818;
  }
  .w-100 {
    margin: 0px;
    padding: 10px 0px;
  }
  .ant-picker-input {
    padding: 5px 10px;
  }
  .style_input {
    color: rgba(100, 100, 100, 1);
    height: 44px;
    .ant-input {
      height: 28px;
      color: rgba(0, 0, 0, 0.85);
      font-weight: 400;
      font-size: 14px;
      @media screen and (max-width: 768px) {
        height: 40px;
      }
    }
  }
`;

const DatePickers = ({
  labelText,
  name,
  placeText,
  sizes,
  req,
  disabledDate,
  datePickerType,
  dateFormat,
  marginBottom,
}) => {
  return (
    <BaseDateWrapped>
      <Form.Item
        name={name ?? "name"}
        label={
          labelText != null ? (req != false ? labelText + " *" : labelText) : ""
        }
        className={marginBottom ? `${marginBottom} w-100` : "w-100 mb-4"}
        rules={[
          {
            required: req === false ? false : true,
            message: "This field is required!!",
          },
        ]}
      >
        <DatePicker
          disabledDate={disabledDate}
          placeholder={placeText ?? "Select date"}
          size={sizes ?? "middle"}
          picker={datePickerType ?? "date"}
          format={dateFormat ?? "YYYY-MM-DD"}
          className='style_input w-100'
        />
      </Form.Item>
    </BaseDateWrapped>
  );
};

export default DatePickers;
