import styled from "styled-components";

const CounterCardStyle = styled.div`
  .cricleboxGreen {
    border: 0.5px solid rgba(51, 161, 97, 0.22);
    box-shadow: 0px 4px 15px rgba(118, 118, 118, 0.08);
    border-radius: 7px;
    .card_style {
      padding: 27px 25px;
      .title {
        font-weight: 700;
        font-size: 16px;
        color: #33a161;
        margin-bottom: 0px;
      }
      .sub_title {
        font-weight: 400;
        font-size: 11px;
        color: #646464;
      }
      .count {
        font-weight: 600;
        font-size: 40px;
        color: #33a161;
        margin-bottom: 0px;
        @media (max-width: 1278px) {
          font-size: 24px;
        }
      }
    }
  }
`;

const JobListCardStyle = styled.div`
  width: 100%;
  background: #FFFEFE;
  border: 2px solid rgba(73, 73, 73, 0.08);
  border-radius: 12px;
  .section-space{
    display:flex;
    justify-content: flex-end;
    @media (max-width: 767px) {
      display:unset;
    }
  }
  .job_view{
    text-decoration-line: underline !important;
    color: #33A161;
  }
  .bi{
    font-size: 16px;
    color: #888190;
  }
  
  .view_btn{
    background-color: rgba(55, 35, 84, 0.15);
    border-radius: 50%;
    padding: 5px;
    width: 32px;
    height: 32px;
    text-align: center;
  }
  ul li {
    border-bottom: 1px solid rgba(209, 209, 209, 0.69) !important;
    text-decoration: unset;
    padding: 30px 0px;
    .list-heading {
      font-weight: 500;
      font-size: 22px;
      color: #19284E;
      margin-bottom: 7px;
    }

    .sub-heading {
      font-weight: 400;
      font-size: 20px;
      color: rgba(25, 40, 78, 0.82);
    }
    .sub2-heading {
      font-weight: 600;
      font-size: 24px;
      color: #19284E;
    }
  }
  .span-main-style {
    font-weight: 500;
    font-size: 14px;
    color: #44444f;
  }
  .job-category {
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.1px;
    color: #92929d;
  }
  .job-time {
    font-weight: 400;
    font-size: 14px;
    color: rgba(55, 35, 84, 0.44);
  }
`;
const JobAppliedCardStyle = styled.div`
  width: 100%;
  background: #FFFEFE;
  border: 2px solid rgba(73, 73, 73, 0.08);
  border-radius: 12px;
  .section-space{
    display:flex;
    justify-content: flex-end;
    @media (max-width: 767px) {
      display:unset;
    }
  }
  .job_view{
    text-decoration-line: underline !important;
    color: #33A161;
  }
  .bi{
    font-size: 16px;
    color: #888190;
  }
  
  .view_btn{
    background-color: rgba(55, 35, 84, 0.15);
    border-radius: 50%;
    padding: 5px;
    width: 32px;
    height: 32px;
    text-align: center;
  }
  ul li {
    border-bottom: 1px solid rgba(209, 209, 209, 0.69) !important;
    text-decoration: unset;
    padding: 30px 0px;
    .list-heading {
      font-weight: 500;
      font-size: 22px;
      color: #19284E;
      margin-bottom: 7px;
    }

    .sub-heading {
      font-weight: 400;
      font-size: 20px;
      color: rgba(25, 40, 78, 0.82);
    }
    .sub2-heading {
      font-weight: 600;
      font-size: 24px;
      color: #19284E;
    }
  }
  .span-main-style {
    font-weight: 500;
    font-size: 14px;
    color: #44444f;
  }
  .job-category {
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.1px;
    color: #92929d;
  }
  .job-time {
    font-weight: 400;
    font-size: 14px;
    color: rgba(55, 35, 84, 0.44);
  }
`;
const ListSubMenuCardWrapped = styled.div``;

const JobApprovedTileWrapped = styled.div`
  border: 1px solid rgba(69, 90, 100, 0.2);
  border-radius: 4px;
  margin-right: 10px;
  margin-bottom: 10px;
  .card_style {
    padding: 16px 35px 16px 20px;
  }

  .title_p {
    font-weight: 400;
    font-size: 12px;
    color: #b5b5be;
  }
  .sub_title {
    font-weight: 500;
    font-size: 14px;
    color: #44444f;
  }
`;
const QrScanCardWrapped = styled.div`
  .qr_image_style {
    box-sizing: border-box;
    width: 185px;
    height: 186px;
    background: #f5f1f1;
    border: 0.5px solid #cccccc;
    border-radius: 5px;
    img {
      padding: 6px;
    }
  }
`;

export {
  CounterCardStyle,
  JobListCardStyle,
  ListSubMenuCardWrapped,
  JobApprovedTileWrapped,
  QrScanCardWrapped,
  JobAppliedCardStyle,
};
