import { Form, Radio } from "antd";
import moment from 'moment';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import TextEditor from "../../components/TextEditor/TextEditor";
import BaseInput from "../../components/form/BaseInput";
import Loading from "../../components/shared/GeneralComponents";
import { STATUSES } from "../../config/constants/defaultValues";

import DatePickers from "../../components/DatePicker/DatePickers";
import SaveButton from "../../components/button/saveButton";
import { errorNotification } from "../../components/notifications/alertNotification";
import Selects from "../../components/select/Selects";
import SeoSection from "../../components/shared/seoSection";
import { getDisciplineListRequest, getHospitalTypeListRequest, getLocationListRequest, getStateListRequest } from "../../redux/appDataSlice";
import { getJobDetailsByIdRequest, getJobEditRequest } from "../../redux/jobsSlice";
import { JobEditWrapped } from "../contentManagement/style";


const JobEdit = () => {
  const { jobID } = useParams();
  console.log(jobID, 'jobID');

  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { error, status, jobCreateStatus } = useSelector(state => state.jobsList)
  const [form] = Form.useForm();



  const [isEditorEmpty, setIsEditorEmpty] = useState(false);

  const [requirementEditorData, setRequirementEditorData] = useState();
  const [requirementEditorEdit, setRequirementEditorEdit] = useState();

  const [benefitsEditorData, setBenefitsEditorData] = useState();
  const [benefitsEditorEdit, setBenefitsEditorEdit] = useState();

  const [descriptionEditorData, setDescriptionEditorData] = useState();
  const [descriptionEditorEdit, setDescriptionEditorEdit] = useState();

  const [editData, setEditData] = useState()
  const [ogImageUrl, setOGImageUrl] = useState();
  const [ogImageFile, setOGImageFile] = useState();
  const [twitterImageUrl, setTwitterImageUrl] = useState();
  const [twitterImageFile, setTwitterImageFile] = useState();

  const [selectedValues, setSelectedValues] = useState(null);
  const { DisciplineData, DisciplineStatus, StateData, StateStatus, HospitalTypeData,
    HospitalTypeStatus, LocationData,
    LocationStatus } = useSelector((state) => state.appData);

  useEffect(() => {
    dispatch(getJobDetailsByIdRequest({ jobID })).then((data) => {
      setEditData(data.payload.job)
    });
  }, [dispatch]);

  useEffect(() => {
    if (editData) {
      form.setFieldsValue({
        discipline_id: editData?.discipline.name,
        state_id: editData?.state.name,
        hospital_type_id: editData?.hospital_type.name,
        location_id: editData?.location_detail.name,
        salary: editData?.salary,
        salary_period: editData?.salary_period,
        shift: editData?.shift,
        duration: editData?.duration,
        start_date: moment(editData?.start_date),
        is_featured: editData?.is_featured,
        is_referral: editData?.is_referral,
        meta_title: editData?.seo_keywords?.meta_title,
        meta_description: editData?.seo_keywords?.meta_description,
        is_canonical: editData?.seo_keywords?.is_canonical,
        robots_meta: editData?.seo_keywords?.robots_meta,
        meta_author: editData?.seo_keywords?.meta_author,
        og_type: editData?.seo_keywords?.og_type,
        og_title: editData?.seo_keywords?.og_title,
        og_description: editData?.seo_keywords?.og_description,
        og_image_width: editData?.seo_keywords?.og_image_width,
        og_image_height: editData?.seo_keywords?.og_image_height,
        og_url: editData?.seo_keywords?.og_url,
        og_site_name: editData?.seo_keywords?.og_site_name,
        og_locale: editData?.seo_keywords?.og_locale,
        og_locale_alternate: editData?.seo_keywords?.og_locale_alternate,
        twitter_card: editData?.seo_keywords?.twitter_card,
        twitter_site: editData?.seo_keywords?.twitter_site,
        twitter_title: editData?.seo_keywords?.twitter_title,
        twitter_description: editData?.seo_keywords?.twitter_description,
        twitter_url: editData?.seo_keywords?.twitter_url,

      });
      setOGImageUrl(editData?.seo_keywords?.og_image);
      setTwitterImageUrl(editData?.seo_keywords?.twitter_image);
      setRequirementEditorEdit(editData?.requirements ?? editData?.discipline?.requirements)
      setBenefitsEditorEdit(editData?.benefits ?? editData?.discipline?.benefits)
      setDescriptionEditorEdit(editData?.description ?? editData?.discipline?.description)
    }
  }, [editData])


  const getDisciplineData = () => {
    if (DisciplineStatus === STATUSES.IDLE) {
      dispatch(getDisciplineListRequest());
    }
  };

  const getStateData = () => {
    if (StateStatus === STATUSES.IDLE) {
      dispatch(getStateListRequest());
    }
  };

  const getHospitalTypeData = () => {
    if (selectedValues?.state?.id) {
      dispatch(getHospitalTypeListRequest({ state: selectedValues.state.id }));
    } else {
      errorNotification("Please Select State First")
    }

  };

  const getLocationData = () => {
    if (selectedValues?.state?.id && selectedValues?.hospitalType?.id) {
      dispatch(getLocationListRequest({ state: selectedValues.state.id, hospitalType: selectedValues.hospitalType.id }));
    } else {
      errorNotification("Please Select State and Hospital-Type First")
    }
  };

  const onDataChange = ({ discipline, state, hospitalType, location }) => {

    // Copy the existing selectedValues into a new object
    const newSelectedValues = { ...selectedValues };

    // Update only the  property in the new object
    if (discipline !== undefined) {
      newSelectedValues.discipline = discipline;
      const foundObject = DisciplineData.collection_list.find(item => item.id === discipline?.id);
      setBenefitsEditorEdit(foundObject?.benefits ?? "  ");
      setDescriptionEditorEdit(foundObject?.description ?? "  ");
      setRequirementEditorEdit(foundObject?.requirements ?? "  ");
    }
    if (state !== undefined) {
      newSelectedValues.state = state;
      newSelectedValues.hospitalType = null;
      newSelectedValues.location = null;
      form.resetFields(['hospital_type_id', 'location_id']);
    }

    if (hospitalType !== undefined) {
      newSelectedValues.hospitalType = hospitalType;
      newSelectedValues.location = null;
      form.resetFields(['location_id']);
    }
    if (location !== undefined) {
      newSelectedValues.location = location;
    }
    setSelectedValues(newSelectedValues);
  };

  //Save Button
  const onSave = (values) => {
    const finalEditData = {
      ...values,
      discipline_id: editData?.discipline.name === values.discipline_id ? editData?.discipline.id : values?.discipline_id,
      state_id: editData?.state.name === values.state_id ? editData?.state.id : values?.state_id,
      hospital_type_id: editData?.hospital_type.name === values.hospital_type_id ? editData?.hospital_type.id : values?.hospital_type_id,
      location_id: editData?.location_detail.name === values.location_id ? editData?.location_detail.id : values?.location_id,
      start_date: values['start_date'].format('YYYY-MM-DD'),
      requirements: requirementEditorData,
      description: descriptionEditorData,
      benefits: benefitsEditorData,
      "og_image": ogImageFile ?? "",
      "twitter_image": twitterImageFile ?? "",
      "is_canonical": values.is_canonical === true ? 1 : 0,
      status: 1,
      _method: 'PUT'
    }
    console.log('finalEditDatafinalEditData', finalEditData);
    dispatch(getJobEditRequest({
      editData: finalEditData,
      id: jobID,
    })).then((data) => {
      if (data.payload.status === 200) {
        navigate('/job-list');
      }
    })
  }

  return (
    <>
      {status === STATUSES.LOADING ? (
        <Loading />
      ) : status === STATUSES.ERROR ? <div>{error}</div> : (
        <JobEditWrapped>
          <div className="row">
            <div className="col-sm-12 justify-content-between d-flex align-items-center px-0 ">
              <div>
                <div className="padding-left-job  d-flex align-items-center">
                  <div
                    className="heading"
                    style={{
                      color: "#372354",
                      fontSize: "16px",
                      cursor: "pointer",
                    }}
                    onClick={() => navigate(-1)}
                  >
                    Jobs List
                  </div>
                  <div className="heading px-3" style={{ fontSize: "16px" }}>
                    {" "}
                    &#62;
                  </div>
                  <div className="heading_child " style={{ fontSize: "16px" }}>
                    {"Jobs Edit "}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12">
              <Form layout="vertical" className="pt-3" onFinish={onSave} form={form}>
                <div className="row">
                  <div className="col-sm-12 d-flex justify-content-between align-items-start mb-4">
                    <h3 className="font-bold" >Job Edit</h3>
                    <SaveButton title="Save" loading={jobCreateStatus} />
                  </div>
                </div>
                <div className="row justify-content-start">
                  <div className="col-xl-10 col-lg-12 col-md-12 col-sm-12">

                    <Selects
                      name="discipline_id"
                      req={true}
                      placeText="Select Discipline"
                      labelText="Select Discipline"
                      notFoundContent={DisciplineStatus === STATUSES.LOADING ? <Loading height={"30vh"} /> : null}
                      onSelect={(_, data) => {
                        onDataChange({ discipline: { id: data.value, name: data.children } });
                      }}
                      onClear={() => {
                        onDataChange({ discipline: null });
                      }}
                      onClick={getDisciplineData}
                      options={DisciplineData?.collection_list ?? []}
                    />
                  </div>
                  <div className="col-xl-5 col-lg-12 col-md-12 col-sm-12">
                    <Selects
                      name="state_id"
                      labelText="State"
                      placeText="State"
                      notFoundContent={StateStatus === STATUSES.LOADING ? <Loading height={"30vh"} /> : null}
                      onSelect={(_, data) => {
                        onDataChange({ state: { id: data.value, name: data.children } });
                      }}
                      onClear={() => {
                        onDataChange({ state: null });
                      }}
                      onClick={getStateData}
                      options={StateData?.collection_list ?? []}
                    />
                  </div>
                  <div className="col-xl-5 col-lg-12 col-md-12 col-sm-12">
                    <Selects
                      name="hospital_type_id"
                      labelText="Hospital Type"
                      placeText="Hospital Type"
                      disabled={selectedValues?.state != null ? false : true}
                      value={selectedValues?.hospitalType == null ? undefined : selectedValues.hospitalType}
                      notFoundContent={HospitalTypeStatus === STATUSES.LOADING ? <Loading height={"30vh"} /> : null}
                      onSelect={(_, data) => {
                        onDataChange({ hospitalType: { id: data.value, name: data.children } });
                      }}
                      onClear={() => {
                        onDataChange({ hospitalType: null });
                      }}
                      onClick={getHospitalTypeData}
                      options={HospitalTypeData ?? []}
                    />
                  </div>
                  <div className="col-xl-10 col-lg-12 col-md-12 col-sm-12">
                    <Selects
                      name="location_id"
                      labelText="Location"
                      placeText="Location"
                      disabled={selectedValues?.state != null && selectedValues?.hospitalType != null ? false : true}
                      notFoundContent={LocationStatus === STATUSES.LOADING ? <Loading height={"30vh"} /> : null}
                      value={selectedValues?.location ?? undefined}
                      onSelect={(_, data) => {
                        onDataChange({ location: { id: data.value, name: data.children } });
                      }}
                      onClear={() => {
                        onDataChange({ location: null });
                      }}
                      onClick={getLocationData}
                      options={LocationData ?? []}
                    />

                    {/* 
                  <Selects
                    name="location_id"
                    labelText="Location"
                    placeText="e.g. Job Blog"
                    options={locationType}

                  /> */}

                  </div>
                  <div className="col-xl-3 col-lg-12 col-md-12 col-sm-12">
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Salary"
                      name="salary"
                      placeText="Add salary"
                    />
                  </div>
                  <div className="col-xl-3 col-lg-12 col-md-12 col-sm-12">
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Salary Period"
                      name="salary_period"
                      placeText="salary_period"
                      value={"Hour"}
                    />
                  </div>
                  <div className="col-xl-3 col-lg-12 col-md-12 col-sm-12">
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Shift"
                      name="shift"
                      placeText="Add shift"
                      value={"First Shift"}
                    />
                  </div>
                  <div className="col-xl-5 col-lg-12 col-md-12 col-sm-12 ">

                    <DatePickers
                      labelText="Start Date"
                      name="start_date"
                      dateFormat={'MM-DD-YYYY'}
                    />
                  </div>
                </div>
                <div class="row">
                  <div className="col-xl-3 col-lg-12 col-md-12 col-sm-12 ">
                    <Form.Item label="Featured" name="is_featured" initialValue={0}>
                      <Radio.Group>
                        <Radio value={1}>Yes</Radio>
                        <Radio value={0}>No</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </div>
                  <div className="col-xl-3 col-lg-12 col-md-12 col-sm-12 ">
                    <Form.Item label="Referral" name="is_referral" initialValue={0}>
                      <Radio.Group>
                        <Radio value={1}>Yes</Radio>
                        <Radio value={0}>No</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </div>
                  <div className="col-xl-10 col-lg-12 col-md-12 col-sm-12">
                    <TextEditor
                      labelText="Description"
                      setEditorData={setDescriptionEditorData}
                      editorEditData={descriptionEditorEdit}
                      setIsEditorEmpty={setIsEditorEmpty}
                    />
                  </div>
                  <div className="col-xl-10 col-lg-12 col-md-12 col-sm-12">
                    <TextEditor
                      labelText="Requirements"
                      setEditorData={setRequirementEditorData}
                      editorEditData={requirementEditorEdit}
                      setIsEditorEmpty={setIsEditorEmpty}
                    />
                  </div>
                  <div className="col-xl-10 col-lg-12 col-md-12 col-sm-12">
                    <TextEditor
                      labelText="Benefits"
                      setEditorData={setBenefitsEditorData}
                      editorEditData={benefitsEditorEdit}
                      setIsEditorEmpty={setIsEditorEmpty}
                    />
                  </div>
                  <SeoSection ogImageUrl={ogImageUrl}
                    setOGImageFile={setOGImageFile}
                    setOGImageUrl={setOGImageUrl}
                    twitterImageUrl={twitterImageUrl}
                    setTwitterImageFile={setTwitterImageFile}
                    setTwitterImageUrl={setTwitterImageUrl} />
                </div>
              </Form>
            </div>
          </div >
        </JobEditWrapped >
      )}
    </>
  );
};
export default JobEdit;
