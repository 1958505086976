import React, { useState, useEffect } from "react";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const TextEditor = ({
  labelText,
  setEditorData,
  editorEditData,
  setIsEditorEmpty,
}) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const maxLength = 3005;

  useEffect(() => {
    if (editorEditData && editorEditData !== null) {
      setEditorState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(editorEditData && editorEditData)
          )
        )
      );
    }
  }, [editorEditData]);

  useEffect(() => {
    if (editorEditData) {
      setEditorData(editorEditData);
    }
  }, [editorEditData]);

  const onEditorStateChange = (editorState) => {
    setIsEditorEmpty(editorState.getCurrentContent().hasText());
    setEditorState(editorState);
    setEditorData(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  const handleBeforeInput = (val) => {
    const textLength = editorState.getCurrentContent().getPlainText().length;
    if (val && textLength >= maxLength) {
      return "handled";
    }
    return "not-handled";
  };

  const handlePastedText = (val) => {
    const textLength = editorState.getCurrentContent().getPlainText().length;
    return val.length + textLength >= maxLength;
  };
  return (
    <>
      <div className='my-4 py4'>
        {labelText && (
          <label className='banner_text_style mb-1'>
            <h4>{labelText}</h4>
          </label>
        )}
        <Editor
          editorState={editorState}
          onEditorStateChange={onEditorStateChange}
          handleBeforeInput={handleBeforeInput}
          handlePastedText={handlePastedText}
          editorClassName='rich__editor'
          wrapperClassName='wrapper__editClass'
          toolbarClassName='editor__toolbar'
          toolbar={{
            options: [
              "inline",
              "blockType",
              "fontSize",
              "list",
              "textAlign",
              "history",
              "link",
            ],
            list: { inDropdown: true },
            textAlign: { inDropdown: true },
          }}
        />
        {/* <span className='d-inline-block w-100 text-right mt-2'> */}
        {/* <p>{editorState.getCurrentContent().getPlainText().length} / 5000</p> */}
        {/* </span> */}
      </div>
    </>
  );
};

export default TextEditor;
