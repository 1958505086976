import { Form } from "antd";
import React, { useEffect, useState } from "react";
import BaseInput from "../../components/form/BaseInput";
import { ContentWrapped } from "./style";
import SaveButton from "../../components/button/saveButton";
import { useDispatch, useSelector } from "react-redux";
import {
  getContactPageContentRequest,
  postContactPageContentRequest,
} from "../../redux/contentManagementSlice";
import Loading from "../../components/shared/GeneralComponents";
import { BannerImage, CONTENT_PAGES, STATUSES } from "../../config/constants/defaultValues";
import TextAreas from "../../components/form/TextAreas";
import SeoSection from "../../components/shared/seoSection";

const ContactPage = () => {
  const dispatch = useDispatch();
  const { contactPageData, contactPageStatus, contactPageError } = useSelector(
    (state) => state.contentManagement
  );
  const [form] = Form.useForm();

  //Seo Section states
  const [ogImageUrl, setOGImageUrl] = useState();
  const [ogImageFile, setOGImageFile] = useState();
  const [twitterImageUrl, setTwitterImageUrl] = useState();
  const [twitterImageFile, setTwitterImageFile] = useState();

  useEffect(() => {
    dispatch(getContactPageContentRequest(CONTENT_PAGES.CONTACT_US_PAGE));
  }, [dispatch]);

  useEffect(() => {
    if (contactPageData) {
      form.setFieldsValue({
        "detail[bannerSection][heading]": contactPageData?.contentPage?.detail?.bannerSection?.heading,
        "detail[bannerSection][description]":contactPageData?.contentPage?.detail?.bannerSection?.description,
        "detail[bannerSection][termsText]":contactPageData?.contentPage?.detail?.bannerSection?.termsText,
        "detail[bannerSection][buttonText]":contactPageData?.contentPage?.detail?.bannerSection?.buttonText,
        "detail[bannerSection][addressText]": contactPageData?.contentPage?.detail?.bannerSection?.addressText,
        "detail[bannerSection][address]": contactPageData?.contentPage?.detail?.bannerSection?.address,
        "detail[bannerSection][phoneText]": contactPageData?.contentPage?.detail?.bannerSection?.phoneText,
        "detail[bannerSection][phone]": contactPageData?.contentPage?.detail?.bannerSection?.phone,
        "detail[bannerSection][emailText]": contactPageData?.contentPage?.detail?.bannerSection?.emailText,
        "detail[bannerSection][email]": contactPageData?.contentPage?.detail?.bannerSection?.email,
      //Seo Values Set
      meta_title: contactPageData?.contentPage?.seo_keywords?.meta_title ?? "",
      meta_description: contactPageData?.contentPage?.seo_keywords?.meta_description ?? "",
      is_canonical: contactPageData?.contentPage?.seo_keywords?.is_canonical === 1 ? true : false,
      robots_meta: contactPageData?.contentPage?.seo_keywords?.robots_meta ?? "",
      meta_author: contactPageData?.contentPage?.seo_keywords?.meta_author ?? "",
      og_type: contactPageData?.contentPage?.seo_keywords?.og_type ?? "",
      og_title: contactPageData?.contentPage?.seo_keywords?.og_title ?? "",
      og_description: contactPageData?.contentPage?.seo_keywords?.og_description ?? "",
      og_image_width: contactPageData?.contentPage?.seo_keywords?.og_image_width ?? "",
      og_image_height: contactPageData?.contentPage?.seo_keywords?.og_image_height ?? "",
      og_url: contactPageData?.contentPage?.seo_keywords?.og_url ?? "",
      og_site_name: contactPageData?.contentPage?.seo_keywords?.og_site_name ?? "",
      og_locale: contactPageData?.contentPage?.seo_keywords?.og_locale ?? "",
      og_locale_alternate: contactPageData?.contentPage?.seo_keywords?.og_locale_alternate ?? "",
      twitter_card: contactPageData?.contentPage?.seo_keywords?.twitter_card ?? "",
      twitter_site: contactPageData?.contentPage?.seo_keywords?.twitter_site ?? "",
      twitter_title: contactPageData?.contentPage?.seo_keywords?.twitter_title ?? "",
      twitter_description: contactPageData?.contentPage?.seo_keywords?.twitter_description ?? "",
      twitter_url: contactPageData?.contentPage?.seo_keywords?.twitter_url ?? "",
    });
    setTwitterImageUrl(contactPageData?.contentPage?.seo_keywords?.twitter_image ?? BannerImage);
    setOGImageUrl(contactPageData?.contentPage?.seo_keywords?.og_image ?? BannerImage);
    }
  }, [contactPageData]);

  const onUpdate = (values) => {
    const data={
      ...values,
      name:'Contact Us',
      "og_image": ogImageFile ?? "",
      "twitter_image": twitterImageFile ?? "",
      "is_canonical": values.is_canonical === true ? 1 : 0,
      _method: 'PUT'
    }
    dispatch(postContactPageContentRequest({data,id:contactPageData?.contentPage?.id})) 
  };
  return (
    <>
      {contactPageStatus === STATUSES.LOADING ? (
        <Loading />
      ) : contactPageError === STATUSES.ERROR ? (
        <div>{contactPageError}</div>
      ) : (
        <ContentWrapped>
          <div className="row">
            <div className="col-sm-12">
              <Form
                layout="vertical"
                className="pt-3"
                form={form}
                onFinish={onUpdate}
              >
                <div className="row">
                  <div className="col-sm-12 d-flex justify-content-between align-items-start mb-4">
                    <h3 className="font-bold" >Contact Page</h3>
                    <SaveButton title="Update" />
                  </div>
                </div>
                <div className="row">
                <div className="col-xl-9 col-lg-12 col-md-12 col-sm-12">
                    {/* Banner Section */}
                    {/* <HeadingTitle title="Banner Section" /> */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Banner Section Heading"
                      name="detail[bannerSection][heading]"
                      placeText="Banner Section Heading"
                    />
                    <TextAreas
                      labelText="Banner Section Description"
                      name="detail[bannerSection][description]"
                      placeText="Banner Section Description"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Banner Section Terms Text"
                      name="detail[bannerSection][termsText]"
                      placeText="Banner Section Terms Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Banner Section Button Text"
                      name="detail[bannerSection][buttonText]"
                      placeText="Banner Section Button Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Banner Section Address Text"
                      name="detail[bannerSection][addressText]"
                      placeText="Banner Section Address Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Banner Section Address"
                      name="detail[bannerSection][address]"
                      placeText="Banner Section Address"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Banner Section Phone Text"
                      name="detail[bannerSection][phoneText]"
                      placeText="Banner Section Phone Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Banner Section Phone"
                      name="detail[bannerSection][phone]"
                      placeText="Banner Section Phone"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Banner Section Email Text"
                      name="detail[bannerSection][emailText]"
                      placeText="Banner Section Email Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Banner Section Email"
                      name="detail[bannerSection][email]"
                      placeText="Banner Section Email"
                    />
                  </div>
                  <SeoSection ogImageUrl={ogImageUrl}
                    setOGImageFile={setOGImageFile}
                    setOGImageUrl={setOGImageUrl}
                    twitterImageUrl={twitterImageUrl}
                    setTwitterImageFile={setTwitterImageFile}
                    setTwitterImageUrl={setTwitterImageUrl} />
                </div>

              </Form>
            </div>
          </div>
        </ContentWrapped>
      )}
    </>
  );
};

export default ContactPage;
