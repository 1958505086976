import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./authSlice";
import dashboardReducer from "./dashboardSlice";
import jobApprovalRequestReducer from "./jobsSlice";
import contentManagementReducer from "./contentManagementSlice";
import jobAppliedRequestReducer from "./jobAppliedSlice";
import contactFormListReducer from "./contactFormListSlice";
import reviewListReducer from "./reviewListSlice";
import blogReducer from "./blogSlice";
import autoCompleteApprovalReducer from "./autoCompleteApprovalRequestSlice";
import systemConfigurationReducer from "./systemConfigurationSlice";
import userListReducer from "./userSlice";
import appDataReducer from "./appDataSlice";
export default configureStore({
  reducer: {
    auth: authReducer,
    dashboard: dashboardReducer,
    jobsList: jobApprovalRequestReducer,
    contentManagement: contentManagementReducer,
    blog: blogReducer,
    autoCompleteApproval: autoCompleteApprovalReducer,
    systemConfiguration: systemConfigurationReducer,
    jobApplied: jobAppliedRequestReducer,
    contactFormList: contactFormListReducer,
    reviewList: reviewListReducer,
    userList: userListReducer,
    appData: appDataReducer,
  },
});
