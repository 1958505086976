import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { successNotification, errorNotification } from "../components/notifications/alertNotification";
import { REQUEST_METHOD, STATUSES } from "../config/constants/defaultValues";
import { CONTACT_FORM_LIST_API, EMAIL_CONTACT_FORM_LIST_API } from "../config/constants/endPoints";
import { apiService } from "../config/services/api.service";


export const getContactFormListRequest = createAsyncThunk(
    "autoCompleteApprovalRequest/getAutoCompleteApprovalRequest",
    async ({ generalSearch, page, pageSize, dateRange }, { rejectWithValue }) => {
        try {
            const response = await apiService(
                CONTACT_FORM_LIST_API,
                REQUEST_METHOD.POST,
                {
                    "pagination": { "page": page ?? 1, "per_page": pageSize ?? 30, "export": "0" },
                    "query": { "status": "", "generalSearch": generalSearch, "dateRange": dateRange ?? "" }
                },
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const getContactFormExportListRequest = createAsyncThunk(
    "autoCompleteApprovalRequest/getContactFormExportListRequest",
    async ({ generalSearch, page, dateRange }, { rejectWithValue }) => {
        try {
            const response = await apiService(
                CONTACT_FORM_LIST_API,
                REQUEST_METHOD.POST,
                {
                    "pagination": { "page": 1, "per_page": 30, "export": "1" },
                    "query": { "status": "", "generalSearch": generalSearch, "dateRange": dateRange ?? "" }
                },
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);

export const getEmailContactListRequest = createAsyncThunk(
    "autoCompleteApprovalRequest/getEmailContactListRequest",
    async ({ generalSearch, page, pageSize, dateRange }, { rejectWithValue }) => {
        try {
            const response = await apiService(
                EMAIL_CONTACT_FORM_LIST_API,
                REQUEST_METHOD.POST,
                {
                    "pagination": { "page": page ?? 1, "per_page": pageSize ?? 30, "export": "0" },
                    "query": { "status": "", "generalSearch": generalSearch, "dateRange": dateRange ?? "" }
                },
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const getEmailFormExportListRequest = createAsyncThunk(
    "autoCompleteApprovalRequest/getEmailFormExportListRequest",
    async ({ generalSearch, page, dateRange }, { rejectWithValue }) => {
        try {
            const response = await apiService(
                EMAIL_CONTACT_FORM_LIST_API,
                REQUEST_METHOD.POST,
                {
                    "pagination": { "page": 1, "per_page": 30, "export": "1" },
                    "query": { "status": "", "generalSearch": generalSearch, "dateRange": dateRange ?? "" }
                },
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const getContactFormDetailsByIdRequest = createAsyncThunk(
    "autoCompleteApprovalRequest/getAutoCompleteDetailsByIdRequest",
    async (contactFormID, { rejectWithValue }) => {
        try {
            const response = await apiService(
                CONTACT_FORM_LIST_API + contactFormID,
                REQUEST_METHOD.GET,
                {},
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);


const contactFormListSlice = createSlice({
    name: "autoCompleteApprovalRequest",
    initialState: {
        contactFormData: null,
        status: STATUSES.IDLE,
        error: null,
        contactFormDetails: null,
        contactFormDetailsStatus: STATUSES.IDLE,
        emailContactListData: null,
        emailStatus: STATUSES.IDLE,
        emailError: null,
    },
    extraReducers: {
        [getContactFormListRequest.pending]: (state, action) => {
            state.status = STATUSES.LOADING;
        },
        [getContactFormListRequest.fulfilled]: (state, action) => {

            state.status = STATUSES.IDLE;
            state.contactFormData = action.payload;
        },
        [getContactFormListRequest.rejected]: (state, action) => {
            state.status = STATUSES.ERROR;
            state.error = action.payload;
        },
        [getEmailContactListRequest.pending]: (state, action) => {
            state.emailStatus = STATUSES.LOADING;
        },
        [getEmailContactListRequest.fulfilled]: (state, action) => {

            state.emailStatus = STATUSES.LOADED;
            state.emailContactListData = action.payload;
        },
        [getEmailContactListRequest.rejected]: (state, action) => {
            state.emailStatus = STATUSES.ERROR;
            state.emailError = action.payload;
        },
        [getContactFormDetailsByIdRequest.pending]: (state, action) => {
            state.contactFormDetailsStatus = STATUSES.LOADING;
        },
        [getContactFormDetailsByIdRequest.fulfilled]: (state, action) => {

            state.status = STATUSES.IDLE;
            state.contactFormDetails = action.payload;
        },
        [getContactFormDetailsByIdRequest.rejected]: (state, action) => {
            state.status = STATUSES.ERROR;
            state.error = action.payload;
        },

    },
});
export default contactFormListSlice.reducer;

