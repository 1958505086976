import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { successNotification, errorNotification } from "../components/notifications/alertNotification";
import { REQUEST_METHOD, STATUSES } from "../config/constants/defaultValues";
import { CONTACT_FORM_LIST_API, GET_WEBSITE_USER_DETAIL_API, GET_WEBSITE_USER_LIST_API } from "../config/constants/endPoints";
import { apiService } from "../config/services/api.service";


export const getWebsiteUserListRequest = createAsyncThunk(
    "WebsiteUsersRequest/getWebsiteUsersRequest",
    async ({ generalSearch, page, pageSize, dateRange }, { rejectWithValue }) => {
        try {
            const response = await apiService(
                GET_WEBSITE_USER_LIST_API,
                REQUEST_METHOD.POST,
                {
                    "pagination": { "page": page ?? 1, "per_page": pageSize ?? 30, "export": "0" },
                    "query": { "status": "1", "generalSearch": generalSearch ?? "", "dateRange": dateRange ?? "" }
                },
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const getAllUsersExportDataRequest = createAsyncThunk(
    "WebsiteUsersRequest/getAllUsersExportDataRequest",
    async ({ generalSearch, dateRange }, { rejectWithValue }) => {
        try {
            const response = await apiService(
                GET_WEBSITE_USER_LIST_API,
                REQUEST_METHOD.POST,
                {
                    "pagination": { "page": 1, "per_page": 30, "export": "1" },
                    "query": { "status": "1", "generalSearch": generalSearch, "dateRange": dateRange ?? "" }
                },
                true,

            );


            if (response.status === 200) {

                return response.data.data;

            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const getUserDetailsByIdRequest = createAsyncThunk(
    "WebsiteUsersRequest/getUserDetailsByIdRequest",
    async (websiteUserID, { rejectWithValue }) => {
        try {
            const response = await apiService(
                GET_WEBSITE_USER_DETAIL_API + websiteUserID,
                REQUEST_METHOD.GET,
                {},
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);


const userSlice = createSlice({
    name: "websiteUsersRequest",
    initialState: {
        websiteUserData: null,
        status: STATUSES.IDLE,
        error: null,
        websiteUserDetails: null,
        websiteUserDetailsStatus: STATUSES.IDLE,
    },
    extraReducers: {
        [getWebsiteUserListRequest.pending]: (state, action) => {
            state.status = STATUSES.LOADING;
        },
        [getWebsiteUserListRequest.fulfilled]: (state, action) => {

            state.status = STATUSES.IDLE;
            state.websiteUserData = action.payload;
        },
        [getWebsiteUserListRequest.rejected]: (state, action) => {
            state.status = STATUSES.ERROR;
            state.error = action.payload;
        },
        [getUserDetailsByIdRequest.pending]: (state, action) => {
            state.websiteUserDetailsStatus = STATUSES.LOADING;
        },
        [getUserDetailsByIdRequest.fulfilled]: (state, action) => {

            state.status = STATUSES.IDLE;
            state.websiteUserDetails = action.payload;
        },
        [getUserDetailsByIdRequest.rejected]: (state, action) => {
            state.status = STATUSES.ERROR;
            state.error = action.payload;
        },

    },
});
export default userSlice.reducer;

