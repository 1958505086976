import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  errorNotification,
  successNotification,
} from "../components/notifications/alertNotification";
import { REQUEST_METHOD, STATUSES } from "../config/constants/defaultValues";
import {
  BLOG_API,
  CATEGORY_API,
  GET_BLOG_LIST_API,
  GET_BLOG_MANAGEMENT_DETAIL_API,
  GET_BLOG_MANAGEMENT_LIST_API,
  GET_SINGLE_BLOG_API,
} from "../config/constants/endPoints";
import { apiService } from "../config/services/api.service";

export const getBlogListRequest = createAsyncThunk(
  "blog/getBlogListRequest",
  async ({ searchText }, { rejectWithValue }) => {
    try {
      const response = await apiService(
        GET_BLOG_LIST_API,
        REQUEST_METHOD.POST,
        {
          pagination: { page: 1, per_page: "20" },
          query: { status: "", generalSearch: "", pluckTitle: "" },
        },
        true
        // GET_BLOG_LIST_API,
      );

      if (response.status === 200) {
        return response.data.data.data;
      } else {
        return errorNotification(Object.values(response.data.errors));
      }
    } catch (error) {
      errorNotification("Something went wrong");
      return rejectWithValue("Something went wrong");
    }
  }
);
export const getBlogDetailRequest = createAsyncThunk(
  "blog/getBlogDetailRequest",
  async ({ blogID }, { rejectWithValue }) => {
    try {
      const response = await apiService(
        GET_SINGLE_BLOG_API + blogID,
        REQUEST_METHOD.GET,
        null,
        true
      );
      if (response.status === 200) {
        return response.data?.data;
      } else {
        return errorNotification(Object.values(response.data.errors));
      }
    } catch (error) {
      errorNotification("Something went wrong");
      return rejectWithValue("Something went wrong");
    }
  }
);
export const getCategoryListRequest = createAsyncThunk(
  "blog/getCategoryListRequest",
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiService(
        CATEGORY_API,
        REQUEST_METHOD.GET,
        null,
        true
      );
      if (response.status === 200) {
        return response.data.data;
      } else {
        return errorNotification(Object.values(response.data.errors));
      }
    } catch (error) {
      errorNotification("Something went wrong");
      return rejectWithValue("Something went wrong");
    }
  }
);
export const blogPostRequest = createAsyncThunk(
  "blog/blogPostRequest",
  async (values, { rejectWithValue }) => {
    try {
      const newData = new FormData();
      for (var key in values) {
        if (key === "tag_ids") {
        } else {
          newData.append(key, values[key]);
        }
      }
      for (var i = 0; i < values.tag_ids.length; i++) {
        newData.append("tag_ids[]", values.tag_ids[i]);
      }

      const response = await apiService(
        BLOG_API,
        REQUEST_METHOD.POST,
        newData,
        true
      );

      if (response.status === 200) {
        successNotification(response.data.message);
        return true;
      } else {
        return errorNotification(Object.values(response.data.errors));
      }
    } catch (error) {
      errorNotification("Something went wrong");
      return rejectWithValue("Something went wrong");
    }
  }
);
export const updateBlogPostRequest = createAsyncThunk(
  "blog/blogPostRequest",
  async (values, { rejectWithValue }) => {
    console.log("valuesvaluesvaluesvalues", values.heading);
    try {
      if (values?.heading) {
        const newData = new FormData();
        for (var key in values) {
          if (key === "tag_ids") {
          } else {
            newData.append(key, values[key]);
          }
        }
        for (var i = 0; i < values.tag_ids.length; i++) {
          newData.append("tag_ids[]", values.tag_ids[i]);
        }
        var response = await apiService(
          BLOG_API + "/" + values.id,
          REQUEST_METHOD.POST,
          newData,
          true
        );
      } else {
        var response = await apiService(
          BLOG_API + "/" + values.id,
          REQUEST_METHOD.POST,
          values,
          true
        );
      }

      if (response.status === 200) {
        successNotification(response.data.message);
        return true;
      } else {
        errorNotification(Object.values(response.data.errors));
        return false;
      }
    } catch (error) {
      errorNotification("Something went wrong");
      return false;
    }
  }
);
export const deleteBlogRequest = createAsyncThunk(
  "blog/deleteBlogRequest",
  async (blogID, { rejectWithValue }) => {
    try {
      const response = await apiService(
        BLOG_API + "/" + blogID,
        REQUEST_METHOD.DELETE,
        null,
        true
      );
      if (response.status === 200) {
        successNotification(response.data.message);
        return true;
      } else {
        errorNotification(Object.values(response.data.errors));
        return false;
      }
    } catch (error) {
      errorNotification("Something went wrong");
      return false;
    }
  }
);

// Blog Management
export const getBlogManagementListRequest = createAsyncThunk(
  "blog/getBlogManagementListRequest",
  async ({ searchText }, { rejectWithValue }) => {
    try {
      const response = await apiService(
        GET_BLOG_MANAGEMENT_LIST_API,
        REQUEST_METHOD.POST,
        {
          pagination: { page: 1, per_page: "20" },
          query: { status: "", generalSearch: "", pluckTitle: "" },
        },
        true
        // GET_BLOG_MANAGEMENT_LIST_API,
      );

      if (response.status === 200) {
        return response.data.data.data;
      } else {
        return errorNotification(Object.values(response.data.errors));
      }
    } catch (error) {
      errorNotification("Something went wrong");
      return rejectWithValue("Something went wrong");
    }
  }
);
export const getBlogManagementDetailRequest = createAsyncThunk(
  "blog/getBlogManagementDetailRequest",
  async ({ blog }, { rejectWithValue }) => {
    try {
      const response = await apiService(
        GET_BLOG_MANAGEMENT_DETAIL_API + blog.id,
        REQUEST_METHOD.GET,
        {
          // "pagination": { "page": 1, "per_page": "20" },
          // "query": { "status": null, "generalSearch": searchText ?? "", "pluckTitle": "" }
        },
        true
        // GET_BLOG_MANAGEMENT_DETAIL_API + blog.id,
      );
      if (response.status === 200) {
        return response.data;
      } else {
        return errorNotification(Object.values(response.data.errors));
      }
    } catch (error) {
      errorNotification("Something went wrong");
      return rejectWithValue("Something went wrong");
    }
  }
);
export const updateBlogManagementRequest = createAsyncThunk(
  "blog/updateBlogManagementRequest",
  async ({ values, blogID }, { rejectWithValue }) => {
    // try {
    //     const response = await apiService(
    //         GET_BLOG_MANAGEMENT_DETAIL_API + blogID,
    //         REQUEST_METHOD.PUT,
    //         values,
    //         true,
    //     );
    //     if (response.status === 200) {
    //         successNotification(response.data.message);
    //         return response.data;
    //     } else {
    //         return errorNotification(Object.values(response.data.errors));
    //     }
    // } catch (error) {
    //     errorNotification("Something went wrong")
    //     return rejectWithValue("Something went wrong");
    // }
  }
);
export const getBogAutocompleteDataRequest = createAsyncThunk(
  "blog/getBogAutocompleteDataRequest",
  async ({ search }, { rejectWithValue }) => {
    try {
      const response = await apiService(
        GET_BLOG_LIST_API + "?search=" + search,
        REQUEST_METHOD.GET,
        {},
        true,
        GET_BLOG_LIST_API + "?search=" + search + "&_fields=id,title"
      );
      if (response.status === 200) {
        return response.data;
      } else {
        return errorNotification(Object.values(response.data.errors));
      }
    } catch (error) {
      errorNotification("Something went wrong");
      return rejectWithValue("Something went wrong");
    }
  }
);

const BlogSlice = createSlice({
  name: "blog",
  initialState: {
    blogListData: null,
    blogListStatus: STATUSES.IDLE,
    blogListError: null,
    // blog Detail
    blogDetailData: null,
    blogDetailStatus: STATUSES.IDLE,
    blogDetailError: null,
    // blog Management
    blogManagementListData: null,
    blogManagementListStatus: STATUSES.IDLE,
    blogManagementListError: null,
    // blog Management Detail
    blogManagementDetailData: null,
    blogManagementDetailStatus: STATUSES.IDLE,
    blogManagementDetailError: null,
    // blog AutoComplete DataList
    blogAutoCompleteData: null,
    blogAutoCompleteStatus: STATUSES.IDLE,
    blogAutoCompleteError: null,
  },
  extraReducers: {
    [getBlogListRequest.pending]: (state) => {
      state.blogListStatus = STATUSES.LOADING;
    },
    [getBlogListRequest.fulfilled]: (state, action) => {
      state.blogListStatus = STATUSES.IDLE;
      state.blogListData = action.payload;
    },
    [getBlogListRequest.rejected]: (state, action) => {
      state.blogListStatus = STATUSES.ERROR;
      state.blogListError = action.payload;
    },
    [getBlogDetailRequest.pending]: (state) => {
      state.blogDetailStatus = STATUSES.LOADING;
    },
    [getBlogDetailRequest.fulfilled]: (state, action) => {
      state.blogDetailStatus = STATUSES.IDLE;
      state.blogDetailData = action.payload;
    },
    [getBlogDetailRequest.rejected]: (state, action) => {
      state.blogDetailStatus = STATUSES.ERROR;
      state.blogDetailError = action.payload;
    },
    // blog Management
    [getBlogManagementListRequest.pending]: (state) => {
      state.blogManagementListStatus = STATUSES.LOADING;
    },
    [getBlogManagementListRequest.fulfilled]: (state, action) => {
      state.blogManagementListStatus = STATUSES.IDLE;
      state.blogManagementListData = action.payload;
    },
    [getBlogManagementListRequest.rejected]: (state, action) => {
      state.blogManagementListStatus = STATUSES.ERROR;
      state.blogManagementListError = action.payload;
    },
    [getBlogManagementDetailRequest.pending]: (state) => {
      state.blogManagementDetailStatus = STATUSES.LOADING;
    },
    [getBlogManagementDetailRequest.fulfilled]: (state, action) => {
      state.blogManagementDetailStatus = STATUSES.IDLE;
      state.blogManagementDetailData = action.payload;
    },
    [getBlogManagementDetailRequest.rejected]: (state, action) => {
      state.blogManagementDetailStatus = STATUSES.ERROR;
      state.blogManagementDetailError = action.payload;
    },
    [getBogAutocompleteDataRequest.pending]: (state) => {
      state.blogAutoCompleteStatus = STATUSES.LOADING;
    },
    [getBogAutocompleteDataRequest.fulfilled]: (state, action) => {
      state.blogAutoCompleteStatus = STATUSES.IDLE;
      state.blogAutoCompleteData = action.payload;
    },
    [getBogAutocompleteDataRequest.rejected]: (state, action) => {
      state.blogAutoCompleteStatus = STATUSES.ERROR;
      state.blogAutoCompleteError = action.payload;
    },
  },
});
export default BlogSlice.reducer;
