import { Form } from "antd";
import React, { useEffect, useState, } from "react";
import SaveButton from "../../components/button/saveButton";
import BaseInput from "../../components/form/BaseInput";
import { HomeWrapped } from "./style";
import { useDispatch, useSelector } from "react-redux";
import {
  getLoginContentRequest,
  postLoginContentRequest,
} from "../../redux/contentManagementSlice";
import { BannerImage, CONTENT_PAGES, STATUSES } from "../../config/constants/defaultValues";
import Loading from "../../components/shared/GeneralComponents";
import TextAreas from "../../components/form/TextAreas";
import SeoSection from "../../components/shared/seoSection";
import BannerImageCard from "../../components/shared/bannerImageCard";

const LoginPage = () => {

  const dispatch = useDispatch();
  const { loginPageData, loginPageStatus, loginPageError } =
    useSelector((state) => state.contentManagement);
  const [form] = Form.useForm();

  const [sideImageUrl, setSideImageUrl] = useState();
  const [sideImageFile, setSideImageFile] = useState();

  //Seo Section states
  const [ogImageUrl, setOGImageUrl] = useState();
  const [ogImageFile, setOGImageFile] = useState();
  const [twitterImageUrl, setTwitterImageUrl] = useState();
  const [twitterImageFile, setTwitterImageFile] = useState();

  const contentPage = loginPageData?.contentPage?.detail;
  useEffect(() => {
    dispatch(getLoginContentRequest(CONTENT_PAGES.LOGIN_PAGE));
  }, [dispatch]);
  useEffect(() => {
    if (loginPageData) {
      form.setFieldsValue({
        "detail[loginSection][title]": contentPage?.loginSection?.title,
        "detail[loginSection][description]": contentPage?.loginSection?.description,
        "detail[loginSection][keepMeLoggedInText]": contentPage?.loginSection?.keepMeLoggedInText,
        "detail[loginSection][forgotPasswordText]": contentPage?.loginSection?.forgotPasswordText,
        "detail[loginSection][agreeText]": contentPage?.loginSection?.agreeText,
        "detail[loginSection][loginButtonText]": contentPage?.loginSection?.loginButtonText,
        "detail[loginSection][haveAccountText]": contentPage?.loginSection?.haveAccountText,
        "detail[loginSection][sideText]": contentPage?.loginSection?.sideText,
        "detail[loginSection][sideDescription]": contentPage?.loginSection?.sideDescription,

        //Seo Values Set
        meta_title: loginPageData?.contentPage?.seo_keywords?.meta_title ?? "",
        meta_description: loginPageData?.contentPage?.seo_keywords?.meta_description ?? "",
        is_canonical: loginPageData?.contentPage?.seo_keywords?.is_canonical === 1 ? true : false,
        robots_meta: loginPageData?.contentPage?.seo_keywords?.robots_meta ?? "",
        meta_author: loginPageData?.contentPage?.seo_keywords?.meta_author ?? "",
        og_type: loginPageData?.contentPage?.seo_keywords?.og_type ?? "",
        og_title: loginPageData?.contentPage?.seo_keywords?.og_title ?? "",
        og_description: loginPageData?.contentPage?.seo_keywords?.og_description ?? "",
        og_image_width: loginPageData?.contentPage?.seo_keywords?.og_image_width ?? "",
        og_image_height: loginPageData?.contentPage?.seo_keywords?.og_image_height ?? "",
        og_url: loginPageData?.contentPage?.seo_keywords?.og_url ?? "",
        og_site_name: loginPageData?.contentPage?.seo_keywords?.og_site_name ?? "",
        og_locale: loginPageData?.contentPage?.seo_keywords?.og_locale ?? "",
        og_locale_alternate: loginPageData?.contentPage?.seo_keywords?.og_locale_alternate ?? "",
        twitter_card: loginPageData?.contentPage?.seo_keywords?.twitter_card ?? "",
        twitter_site: loginPageData?.contentPage?.seo_keywords?.twitter_site ?? "",
        twitter_title: loginPageData?.contentPage?.seo_keywords?.twitter_title ?? "",
        twitter_description: loginPageData?.contentPage?.seo_keywords?.twitter_description ?? "",
        twitter_url: loginPageData?.contentPage?.seo_keywords?.twitter_url ?? "",
      });
      setSideImageUrl(loginPageData?.contentPage?.contentImages?.sideImage ?? BannerImage);
      setTwitterImageUrl(loginPageData?.contentPage?.seo_keywords?.twitter_image ?? BannerImage);
      setOGImageUrl(loginPageData?.contentPage?.seo_keywords?.og_image ?? BannerImage);

    }
  }, [loginPageData]);

  const onUpdate = (values) => {

    const data = {
      ...values,
      name: 'Login Page',
      "og_image": ogImageFile ?? "",
      "twitter_image": twitterImageFile ?? "",
      "is_canonical": values.is_canonical === true ? 1 : 0,
      "contentImages[sideImage]": sideImageFile ?? "",
      _method: 'PUT'
    }
    dispatch(postLoginContentRequest({ data, id: loginPageData?.contentPage?.id }))
  };

  return (
    <>
      {loginPageStatus === STATUSES.LOADING ? (
        <Loading />
      ) : loginPageStatus === STATUSES.ERROR ? (
        <div>{loginPageError}</div>
      ) : (
        <HomeWrapped>
          <div className="row">
            <div className="col-sm-12">
              <Form
                layout="vertical"
                className="pt-3"
                form={form}
                onFinish={onUpdate}

              >
                <div className="row">
                  <div className="col-sm-12 d-flex justify-content-between align-items-start mb-4">
                    <h3 className="font-bold" >Login Page</h3>
                    <SaveButton title="Update" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-9 col-lg-12 col-md-12 col-sm-12">
                    {/* Banner Section */}
                    {/* <HeadingTitle title="Banner Section" /> */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Login Section Title"
                      name="detail[loginSection][title]"
                      placeText="Login Section Title"
                    />
                    <TextAreas
                      labelText="Login Section Description"
                      name="detail[loginSection][description]"
                      placeText="Login Section Description"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Login Section Keep Me Logged In Text"
                      name="detail[loginSection][keepMeLoggedInText]"
                      placeText="Login Section Keep Me Logged In Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Login Section Forgot Password Text"
                      name="detail[loginSection][forgotPasswordText]"
                      placeText="Login Section Forgot Password Text"
                    />
                    <TextAreas
                      labelText="Login Section Agree Text"
                      name="detail[loginSection][agreeText]"
                      placeText="Login Section Agree Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Login Section Login Button Text"
                      name="detail[loginSection][loginButtonText]"
                      placeText="Login Section Login Button Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Login Section Have Account Text"
                      name="detail[loginSection][haveAccountText]"
                      placeText="Login Section Have Account Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Side Text"
                      name="detail[loginSection][sideText]"
                      placeText="Side Text"
                    />
                    <TextAreas
                      labelText="Side Description"
                      name="detail[loginSection][sideDescription]"
                      placeText="Side Description"
                    />
                    <BannerImageCard
                      title="Side Image"
                      imageUrl={sideImageUrl}
                      isBlogImage={true}
                      setImageFile={setSideImageFile}
                      setImageUrl={setSideImageUrl}
                      imageText="Change Image"
                    />
                  </div>
                  <SeoSection ogImageUrl={ogImageUrl}
                    setOGImageFile={setOGImageFile}
                    setOGImageUrl={setOGImageUrl}
                    twitterImageUrl={twitterImageUrl}
                    setTwitterImageFile={setTwitterImageFile}
                    setTwitterImageUrl={setTwitterImageUrl} />
                </div>
              </Form>
            </div>
          </div>
        </HomeWrapped>
      )}
    </>
  );
};

export default LoginPage;
