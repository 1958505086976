import { Form } from "antd";
import React, { useEffect, useState, } from "react";
import SaveButton from "../../components/button/saveButton";
import BaseInput from "../../components/form/BaseInput";
import { HomeWrapped } from "./style";
import { useDispatch, useSelector } from "react-redux";
import {
  getForgotPasswordContentRequest,
  postForgotPasswordContentRequest,
} from "../../redux/contentManagementSlice";
import { BannerImage, CONTENT_PAGES, STATUSES } from "../../config/constants/defaultValues";
import Loading from "../../components/shared/GeneralComponents";
import TextAreas from "../../components/form/TextAreas";
import SeoSection from "../../components/shared/seoSection";
import BannerImageCard from "../../components/shared/bannerImageCard";

const ForgotPasswordPage = () => {

  const dispatch = useDispatch();
  const { forgotPasswordPageData, forgotPasswordPageStatus, forgotPasswordPageError } =
    useSelector((state) => state.contentManagement);
  const [form] = Form.useForm();

  const [sideImageUrl, setSideImageUrl] = useState();
  const [sideImageFile, setSideImageFile] = useState();

  //Seo Section states
  const [ogImageUrl, setOGImageUrl] = useState();
  const [ogImageFile, setOGImageFile] = useState();
  const [twitterImageUrl, setTwitterImageUrl] = useState();
  const [twitterImageFile, setTwitterImageFile] = useState();

  const contentPage = forgotPasswordPageData?.contentPage?.detail;
  useEffect(() => {
    dispatch(getForgotPasswordContentRequest(CONTENT_PAGES.FORGOT_PASSWORD_PAGE));
  }, [dispatch]);
  useEffect(() => {
    if (forgotPasswordPageData) {
      form.setFieldsValue({
        "detail[forgotPasswordSection][title]": contentPage?.forgotPasswordSection?.title,
        "detail[forgotPasswordSection][description]": contentPage?.forgotPasswordSection?.description,
        "detail[forgotPasswordSection][buttonText]": contentPage?.forgotPasswordSection?.buttonText,
        "detail[forgotPasswordSection][backToLoginText]": contentPage?.forgotPasswordSection?.backToLoginText,
        "detail[forgotPasswordSection][sideText]": contentPage?.forgotPasswordSection?.sideText,
        "detail[forgotPasswordSection][sideDescription]": contentPage?.forgotPasswordSection?.sideDescription,
        //Seo Values Set
        meta_title: forgotPasswordPageData?.contentPage?.seo_keywords?.meta_title ?? "",
        meta_description: forgotPasswordPageData?.contentPage?.seo_keywords?.meta_description ?? "",
        is_canonical: forgotPasswordPageData?.contentPage?.seo_keywords?.is_canonical === 1 ? true : false,
        robots_meta: forgotPasswordPageData?.contentPage?.seo_keywords?.robots_meta ?? "",
        meta_author: forgotPasswordPageData?.contentPage?.seo_keywords?.meta_author ?? "",
        og_type: forgotPasswordPageData?.contentPage?.seo_keywords?.og_type ?? "",
        og_title: forgotPasswordPageData?.contentPage?.seo_keywords?.og_title ?? "",
        og_description: forgotPasswordPageData?.contentPage?.seo_keywords?.og_description ?? "",
        og_image_width: forgotPasswordPageData?.contentPage?.seo_keywords?.og_image_width ?? "",
        og_image_height: forgotPasswordPageData?.contentPage?.seo_keywords?.og_image_height ?? "",
        og_url: forgotPasswordPageData?.contentPage?.seo_keywords?.og_url ?? "",
        og_site_name: forgotPasswordPageData?.contentPage?.seo_keywords?.og_site_name ?? "",
        og_locale: forgotPasswordPageData?.contentPage?.seo_keywords?.og_locale ?? "",
        og_locale_alternate: forgotPasswordPageData?.contentPage?.seo_keywords?.og_locale_alternate ?? "",
        twitter_card: forgotPasswordPageData?.contentPage?.seo_keywords?.twitter_card ?? "",
        twitter_site: forgotPasswordPageData?.contentPage?.seo_keywords?.twitter_site ?? "",
        twitter_title: forgotPasswordPageData?.contentPage?.seo_keywords?.twitter_title ?? "",
        twitter_description: forgotPasswordPageData?.contentPage?.seo_keywords?.twitter_description ?? "",
        twitter_url: forgotPasswordPageData?.contentPage?.seo_keywords?.twitter_url ?? "",
      });
      setSideImageUrl(forgotPasswordPageData?.contentPage?.contentImages?.sideImage ?? BannerImage);
      setTwitterImageUrl(forgotPasswordPageData?.contentPage?.seo_keywords?.twitter_image ?? BannerImage);
      setOGImageUrl(forgotPasswordPageData?.contentPage?.seo_keywords?.og_image ?? BannerImage);

    }
  }, [forgotPasswordPageData]);

  const onUpdate = (values) => {
    
    const data = {
      ...values,
      name: 'Forgot Password Page',
      "og_image": ogImageFile ?? "",
      "twitter_image": twitterImageFile ?? "",
      "is_canonical": values.is_canonical === true ? 1 : 0,
      "contentImages[sideImage]": sideImageFile ?? "",
      _method: 'PUT'
    }
    dispatch(postForgotPasswordContentRequest({ data, id: forgotPasswordPageData?.contentPage?.id }))
  };

  return (
    <>
      {forgotPasswordPageStatus === STATUSES.LOADING ? (
        <Loading />
      ) : forgotPasswordPageStatus === STATUSES.ERROR ? (
        <div>{forgotPasswordPageError}</div>
      ) : (
        <HomeWrapped>
          <div className="row">
            <div className="col-sm-12">
              <Form
                layout="vertical"
                className="pt-3"
                form={form}
                onFinish={onUpdate}

              >
                <div className="row">
                  <div className="col-sm-12 d-flex justify-content-between align-items-start mb-4">
                    <h3 className="font-bold" >Forgot Password Page</h3>
                    <SaveButton title="Update" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-9 col-lg-12 col-md-12 col-sm-12">
                    {/* Banner Section */}
                    {/* <HeadingTitle title="Banner Section" /> */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Forgot Password Section Title"
                      name="detail[forgotPasswordSection][title]"
                      placeText="Forgot Password Section Title"
                    />
                    <TextAreas
                      labelText="Forgot Password Section Description"
                      name="detail[forgotPasswordSection][description]"
                      placeText="Forgot Password Section Description"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Forgot Password Section Button Text"
                      name="detail[forgotPasswordSection][buttonText]"
                      placeText="Forgot Password Section Button Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Forgot Password Section Back To Login Text"
                      name="detail[forgotPasswordSection][backToLoginText]"
                      placeText="Forgot Password Section Back To Login Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Side Text"
                      name="detail[forgotPasswordSection][sideText]"
                      placeText="Side Text"
                    />
                    <TextAreas
                      labelText="Side Description"
                      name="detail[forgotPasswordSection][sideDescription]"
                      placeText="Side Description"
                    />
                    <BannerImageCard
                      title="Side Image"
                      imageUrl={sideImageUrl}
                      isBlogImage={true}
                      setImageFile={setSideImageFile}
                      setImageUrl={setSideImageUrl}
                      imageText="Change Image"
                    />
                  </div>
                  <SeoSection ogImageUrl={ogImageUrl}
                    setOGImageFile={setOGImageFile}
                    setOGImageUrl={setOGImageUrl}
                    twitterImageUrl={twitterImageUrl}
                    setTwitterImageFile={setTwitterImageFile}
                    setTwitterImageUrl={setTwitterImageUrl} />
                </div>
              </Form>
            </div>
          </div>
        </HomeWrapped>
      )}
    </>
  );
};

export default ForgotPasswordPage;
