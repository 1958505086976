import { Form, Modal } from "antd";
import React, { useEffect, useState } from "react";
import SaveButton from "../../../components/button/saveButton";
import BaseInput from "../../../components/form/BaseInput";
import {
  editConfigurationalTestimonialRequest,
  getConfigurationalTestimonialListRequest,
} from "../../../redux/systemConfigurationSlice";
import { BannerImage, STATUSES } from "../../../config/constants/defaultValues";
import { useDispatch } from "react-redux";
import BannerImageCard from "../../../components/shared/bannerImageCard";
import TextAreas from "../../../components/form/TextAreas";

const TestimonialEditModal = ({ isModalOpen, setIsModalOpen, modalData }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [userImageUrl, setUserImageUrl] = useState();
  const [userImageFile, setUserImageFile] = useState();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (modalData) {
      form.setFieldsValue({
        user_name: modalData?.user_name,
        job_title: modalData?.job_title,
        title: modalData?.title,
        description: modalData?.description,
      });
      setUserImageUrl(modalData?.user_image ?? BannerImage);
    }
  }, [modalData]);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  //Save Button
  const onSave = async (formData) => {
    var values = {
      ...formData,
      user_image: userImageFile ?? "",
      testimonial_id: modalData?.id,
      status: "1",
      _method: "PUT",
    };
    setIsLoading(true);
    await dispatch(
      editConfigurationalTestimonialRequest({
        values
      })
    ).then((data) => {
      if(data.payload.status === 200) {
        dispatch(getConfigurationalTestimonialListRequest({}));
        setIsModalOpen(false);
      }
      setIsLoading(false);
    });
  };
  return (
    <>
      <Modal
        className="modal-styles"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        maskClosable={false}
        centered={true}
        title={false}
      >
        <div className="row">
          <div className="col-md-12">
            <h5 className="table-heading mb-0 ">Edit Testimonial</h5>
          </div>
        </div>
        <div className="modal-content">
          <Form
            layout="vertical"
            className="pt-3"
            onFinish={onSave}
            form={form} >
            <div className="row">
              <div className="col-sm-12 d-flex justify-content-between align-items-start">
                <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12">
                  <BaseInput
                    inputIcon={<i className="bi bi-pencil icon_style"></i>}
                    labelText="User Name"
                    name="user_name"
                    placeText="User Name"
                  />
                </div>
                <SaveButton
                  loading={isLoading ? STATUSES.LOADING : false}
                  title="Save"
                />
              </div>
            </div>

            <div class="row">
              <div className="col-xl-10 col-lg-12 col-md-12 col-sm-12">
                <BannerImageCard
                  title="User Image"
                  imageUrl={userImageUrl ?? BannerImage}
                  isBlogImage={true}
                  setImageFile={setUserImageFile}
                  setImageUrl={setUserImageUrl}
                  imageText="Change Image"
                />
              </div>
              <div className="col-xl-10 col-lg-12 col-md-12 col-sm-12">
                <BaseInput
                  inputIcon={<i className="bi bi-pencil icon_style"></i>}
                  labelText="Job Title"
                  name="job_title"
                  placeText="Add Job Title"
                />
              </div>
              <div className="col-xl-10 col-lg-12 col-md-12 col-sm-12">
                <BaseInput
                  inputIcon={<i className="bi bi-pencil icon_style"></i>}
                  labelText="Testimonial Title"
                  name="title"
                  placeText="Add Testimonial Title"
                />
              </div>
              <div className="col-xl-10 col-lg-12 col-md-12 col-sm-12"></div>
              <div className="col-xl-10 col-lg-12 col-md-12 col-sm-12">
                <TextAreas
                  labelText="Description"
                  name="description"
                  placeText="Description"
                />
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  );
};
export default TestimonialEditModal;
