import styled from "styled-components";

const SystemConfigurationWrapped = styled.div`
  padding: 0px 0px 0px 10px;
  @media screen and (max-width: 768px) {
    padding: 0px 0px 0px 0px;
  }
 
  .ant-table {
    .ant-table-tbody > tr > td,
    .ant-table-thead > tr > th {
      padding: 16px 20px;
    }
  }
  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th {
    white-space: nowrap;
  }
`;
const SystemConfigurationSideBarWrapped = styled.div`
  padding-left: 20px;
  @media screen and (max-width: 1024px) {
    padding-left: 0px;
  }
  .side-tabs {
    position: fixed;
    width: 300px;
    background: #f5f5f5;
    border: 0.8px solid #cccccc;
    border-radius: 5px;
    overflow: auto;
    margin: 0px auto;
    height: 700px;
    margin-bottom: 20px;
    padding: 25px 15px;
    margin-left: 30px;
    ul li a {
      font-weight: 400;
      font-size: 16px;
      color: rgba(128, 128, 128, 1);
    }
    // @media screen and (max-width: 1580px) {
    //   margin-left: 0px !important;
    // }
    @media screen and (max-width: 1440px) {
      padding: 20px 8px;
      width: 260px;
      margin-left: 0px !important;
      // height: 650px;
    }
    @media screen and (max-width: 1390px) {
      padding: 20px 8px;
      width: 200px;
      // margin-left: -20px !important;
      // height: 650px;
    }
    // @media screen and (max-width: 1150px) {
    //   width: 180px;
    // }
    @media screen and (max-width: 991px) {
      padding: 20px 8px;
      margin-left: 0px !important;
      width: 100%;
      height: 100%;
      position: relative;
    }
    // @media screen and (max-width: 768px) {
    //   width: 160px;
    //   margin-left: 0px;
    // }
    @media screen and (max-width: 575px) {
      position: unset;
      width: auto;
      height: auto;
    }
    .search_style {
      margin: 0px !important;
    }
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background-color: transparent;
      font-weight: 600;
      font-size: 16px;
      color: #372354;
    }
    .ant-menu-item,
    .ant-menu-submenu-title {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;

      color: #808080;
    }
    .ant-menu-light .ant-menu-item:hover,
    .ant-menu-light .ant-menu-item-active,
    .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
    .ant-menu-light .ant-menu-submenu-active,
    .ant-menu-light .ant-menu-submenu-title:hover {
      color: #372354;
    }
    .ant-menu-inline .ant-menu-selected::after,
    .ant-menu-inline .ant-menu-item-selected::after {
      opacity: 0;
    }
    .ant-menu-inline.ant-menu-root .ant-menu-item,
    .ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
      display: block;
      padding-left: 0px !important;
    }
  }
  .side-tabs .ant-input-affix-wrapper {
    padding: 4px 11px;
    color: rgba(55, 35, 84, 0.25);
    border-radius: 5px;
    font-weight: 400;
    font-size: 12px;
    border: 0.6px solid rgba(0, 0, 0, 0.2);
    background: #f5f5f5 !important;
    .ant-input {
      height: 26px;
      background: #f5f5f5 !important;
      font-weight: 400;
      font-size: 12px;
      color: rgba(55, 35, 84, 0.25);
    }
  }
`;
export { SystemConfigurationWrapped, SystemConfigurationSideBarWrapped };
