import styled from "styled-components";

const ContentWrapped = styled.div`
  padding: 0px 0px 0px 30px;
  @media screen and (max-width: 768px) {
    padding: 0px 0px 0px 0px;
  }
  .section {
    padding-bottom: 15px;
  }
`;
const HomeWrapped = styled.div`
  padding: 0px 0px 0px 30px;
  @media screen and (max-width: 768px) {
    padding: 0px 0px 0px 0px;
  }
  .section {
    padding-bottom: 15px;
  }
`;
const JobCreateWrapped = styled.div`
padding: 0px 90px 0px 110px;
  @media screen and (max-width: 768px) {
    padding: 0px 0px 0px 0px;
  }
`;
const JobEditWrapped = styled.div`
  padding: 0px 0px 0px 50px;
  @media screen and (max-width: 768px) {
    padding: 0px 0px 0px 0px;
  }
`;
export { ContentWrapped, HomeWrapped ,JobCreateWrapped, JobEditWrapped};
