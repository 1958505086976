import styled from "styled-components";

const HomeStyle = styled.div`
  .layout-content {
    .table-responsive {
      table {
        thead {
          display: none;
        }
      }
    }
    .chart-style{
      padding: 0px 50px 50px;
      @media screen and (max-width: 1199px) {
        padding: 0 10px;
      }
    }
  }
`;

const JobRequestStyle = styled.div`
.layout-content{
  padding: 40px 50px;
  @media screen and (max-width: 912px) {
    padding: 0px 0px;
  }
}
  .layout-content .ant-input-affix-wrapper {
    background: rgba(55, 35, 84, 0.13);
    border: 0.5px solid rgba(164, 164, 164, 0.1);
    box-shadow: 0px 2px 3px 1px rgba(113, 113, 113, 0.04);
    border-radius: 8px !important;
    padding: 4px 11px;
    color: rgba(55, 35, 84, 0.25);
    font-weight: 400;
    font-size: 20px;
    border-right-width: 1px !important;
    // z-index: 1;
    border: 0.6px solid rgba(0, 0, 0, 0.2);
    background: #f5f5f5 !important;
    .ant-input {
      background: #f5f5f5 !important;
      font-weight: 400;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.85);
      margin-right:15px;
    }
  }
  .ant-form-item {
    margin-bottom: 0px;
  }
  .layout-content {
    .collape-border {
      border: none;
      .panel-tab {
        background: #ffffff;
        border-bottom: none;
        .ant-collapse-header {
          background: #ffffff;
          border: 1.5px solid rgba(0, 0, 0, 0.09);
          border-radius: 12px;
          margin-bottom: 10px;
          margin-top: 10px;
        }
        .ant-collapse-content {
          margin-top: 10px;
          margin-bottom: 10px;
          background: #ffffff;
          border: 1.5px solid rgba(0, 0, 0, 0.09);
          border-radius: 12px;
        }
      }
    }
  }
  .ant-row {
    display: inline;
}

.ant-form{
  label{
    font-size:16px; 
  }
}
`;
const JobAppliedStyle = styled.div`

  .layout-content .ant-input-affix-wrapper {
    background: rgba(55, 35, 84, 0.13);
    border: 0.5px solid rgba(164, 164, 164, 0.1);
    box-shadow: 0px 2px 3px 1px rgba(113, 113, 113, 0.04);
    border-radius: 8px !important;
    padding: 4px 11px;
    color: rgba(55, 35, 84, 0.25);
    font-weight: 400;
    font-size: 20px;
    border-right-width: 1px !important;
    z-index: 1;
    border: 0.6px solid rgba(0, 0, 0, 0.2);
    background: #f5f5f5 !important;
    .ant-input {
      background: #f5f5f5 !important;
      font-weight: 400;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.85);
      margin-right:15px;
    }
  }
  .ant-form-item {
    margin-bottom: 0px;
  }
  .layout-content {
    .collape-border {
      border: none;
      .panel-tab {
        background: #ffffff;
        border-bottom: none;
        .ant-collapse-header {
          background: #ffffff;
          border: 1.5px solid rgba(0, 0, 0, 0.09);
          border-radius: 12px;
          margin-bottom: 10px;
          margin-top: 10px;
        }
        .ant-collapse-content {
          margin-top: 10px;
          margin-bottom: 10px;
          background: #ffffff;
          border: 1.5px solid rgba(0, 0, 0, 0.09);
          border-radius: 12px;
        }
      }
    }
  }
`;

const ContentManagementWrapped = styled.div`
.layout-content{
  /* padding: 40px 50px;
  @media screen and (max-width: 768px) {
    padding: 0;
  } */
}
  @media screen and (max-width: 768px) {
    margin-top: 0px;
  }
`;
const TabPanelDetailStyle = styled.div`
  padding: 15px 25px;
  @media screen and (max-width: 1199px) {
    padding: 15px 20px;
  }
  .section-style {
    margin-top: 20px;
    background: #FFFEFE;
    border: 2px solid rgba(73, 73, 73, 0.08);
    border-radius: 12px;
    @media screen and (max-width: 1199px) {
      padding: 25px 0px;
    }
    .list-heading {
      font-weight: 500;
      font-size: 30px;
      color:#19284E;
      margin-bottom: 7px;
    }

    .sub-heading {
      font-weight: 400;
      font-size: 24px;
      color: rgba(25, 40, 78, 0.82);
    }
    .sub2-heading {
      font-weight: 600;
      font-size: 24px;
      color: #19284E;
    }
  }
  
`;
const SignInWrapped = styled.div`
  input:-internal-autofill-selected {
    background-color: #e7f0fe !important;
  }
  .ant-input {
    background-color: #e7f0fe !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    color: #372354 !important;
    height: 40px;
    border: 1.5px solid #ffffff;
  }
  .ant-input-affix-wrapper > input.ant-input:focus {
    background-color: #e7f0fe;
  }
  .ant-form-item-has-error
    :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
  .ant-form-item-has-error
    :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
  .ant-form-item-has-error
    :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
  .ant-form-item-has-error
    :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
    background-color: #372354;
    border-color: #ff4d4f;
  }
`;
const AutoCompleteRequestWrapped = styled.div`
  padding: 0px 0px 0px 20px;
  .auto_section {
    background: #fcfbfb;
    border: 1px solid rgba(168, 168, 168, 0.09);
    border-radius: 12px;
    padding: 10px 0px;
    margin-top: 20px;
  }
`;
const ContactFormListWrapped = styled.div`
  padding: 0px 0px 0px 20px;
  @media screen and (max-width: 991px) {
    padding: 0px 0px 0px 0px;
  }
  .auto_section {
    width: 100%;
    background: rgb(255, 254, 254);
    border: 2px solid rgba(73, 73, 73, 0.08);
    border-radius: 12px;
    margin-top: 20px;
  }
`;
const ReviewListWrapped = styled.div`
  padding: 0px 0px 0px 20px;
  @media screen and (max-width: 991px) {
    padding: 0px 0px 0px 0px;
  }
  .auto_section {
    width: 100%;
    background: rgb(255, 254, 254);
    border: 2px solid rgba(73, 73, 73, 0.08);
    border-radius: 12px;
    margin-top: 20px;
  }

`;


export {
  HomeStyle,
  ContentManagementWrapped,
  TabPanelDetailStyle,
  SignInWrapped,
  JobRequestStyle,
  AutoCompleteRequestWrapped,
  JobAppliedStyle,
  ContactFormListWrapped,
  ReviewListWrapped
};
