import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { errorNotification, successNotification } from "../components/notifications/alertNotification";
import { REQUEST_METHOD, STATUSES } from "../config/constants/defaultValues";
import { JOBS_API, JOBS_LOCATION_LIST_API, JOBS_STATE_TYPE_API, JOB_LIST_API } from "../config/constants/endPoints";
import { apiService } from "../config/services/api.service";


export const getAllJobRequestDataRequest = createAsyncThunk(
    "jobData/getAllJobRequestDataRequest",
    async ({ generalSearch, page, pageSize, discipline, state, hospitalType, location, dateRange, status, isFeatured, isReferral }, { rejectWithValue }) => {
        try {
            const response = await apiService(
                JOB_LIST_API,
                REQUEST_METHOD.POST,
                {
                    "pagination": { "page": page ?? 1, "per_page": pageSize ?? 30, "export": "0" },
                    "query": {
                        "status": status,
                        "featured": isFeatured,
                        "referral": isReferral,
                        "generalSearch": generalSearch,
                        "discipline": discipline ?? "",
                        "state": state ?? "",
                        "hospitalType": hospitalType ?? "",
                        "location": location ?? "",
                        "dateRange": dateRange ?? ""
                    }
                },
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);

export const getAllJobsExportDataRequest = createAsyncThunk(
    "jobsRequest/getAllJobsExportDataRequest",
    async ({ generalSearch, discipline, state, hospitalType, location, dateRange, status, isFeatured, isReferral }, { rejectWithValue }) => {
        try {
            const response = await apiService(
                JOB_LIST_API,
                REQUEST_METHOD.POST,
                {
                    "pagination": { "page": 1, "per_page": 30, "export": "1" },
                    "query": {
                        "status": status ?? 0,
                        "featured": isFeatured ?? 0,
                        "referral": isReferral ?? 0,
                        "generalSearch": generalSearch,
                        "discipline": discipline ?? "",
                        "state": state ?? "",
                        "hospitalType": hospitalType ?? "",
                        "location": location ?? "",
                        "dateRange": dateRange ?? ""
                    }
                },
                true,
            );
            if (response.status === 200) {

                return response.data.data;

            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const jobApprovalRejectAndAcceptRequest = createAsyncThunk(
    "jobApprovalRequest/jobApprovalRejectRequest",
    async ({ jobID, jobStatus }, { rejectWithValue }) => {
        // try {
        //     const response = await apiService(
        //         JOB_APPROVAL_Request_API,
        //         REQUEST_METHOD.POST,
        //         {
        //             "job_id": jobID,
        //             "status": jobStatus,

        //         },
        //         true,
        //     );
        //     if (response.status === 200) {
        //         successNotification(response.data.message)
        //         return response.data.data;
        //     } else {
        //         return errorNotification(Object.values(response.data.errors));
        //     }
        // } catch (error) {
        //     errorNotification("Something went wrong")
        //     return rejectWithValue("Something went wrong");
        // }
    }
);

export const getJobDetailsByIdRequest = createAsyncThunk(
    "jobApprovalRequest/jobDetails",
    async ({ jobID }, { rejectWithValue }) => {
        try {
            const response = await apiService(
                JOBS_API + jobID,
                REQUEST_METHOD.GET,
                {},
                true,

            );
            if (response.status === 200) {

                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
// create job
export const postJobCreateRequest = createAsyncThunk(
    "jobApprovalRequest/postJobCreate",
    async (values, { rejectWithValue }) => {

        const newData = new FormData();
        Object.entries(values).forEach(([key, value]) => {
            newData.append(key, value === undefined ? null : value);
        });


        try {
            // const newData = new FormData();
            const response = await apiService(
                JOBS_API,
                REQUEST_METHOD.POST,
                newData,
                true
            );

            if (response.status === 200) {
                successNotification(response.data.message);
                return response;
            }
            else {
                errorNotification(Object.values(response.data.errors));
                return response;
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const getJobEditRequest = createAsyncThunk(
    "jobApprovalRequest/postJobCreate",
    async ({ editData, id }, { rejectWithValue }) => {
        const newData = new FormData();
        for (var key in editData) {
            newData.append(key, editData[key])
        }
        try {
            // const newData = new FormData();
            const response = await apiService(
                JOBS_API + id,
                REQUEST_METHOD.POST,
                newData,
                true
            );

            if (response.status === 200) {
                successNotification(response.data.message);
                return response;
            }
            else {
                errorNotification(Object.values(response.data.errors));
                return response;
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
// JOB STATE TYPE
export const getJobStateTypeDataRequest = createAsyncThunk(
    "jobApprovalRequest/jobStateTypeDataRequest",
    async (id, { rejectWithValue }) => {

        try {
            const response = await apiService(
                JOBS_STATE_TYPE_API + id,
                REQUEST_METHOD.POST,
                null,
                true,

            );


            if (response.status === 200) {
                // console.log("dddddddddresponsemConfigurationDetailRequest", response)
                return response.data.data;

            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
// JOB location type
export const getJobLocationListDataRequest = createAsyncThunk(
    "jobApprovalRequest/jobStateTypeDataRequest",
    async (id, { rejectWithValue }) => {

        try {
            const response = await apiService(
                JOBS_LOCATION_LIST_API + id,
                REQUEST_METHOD.POST,
                null,
                true,

            );


            if (response.status === 200) {
                console.log("dddddddddresponsemConfigurationDetailRequest", response)
                return response.data.data;

            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);

const JobApprovalRequestSlice = createSlice({
    name: "jobApprovalRequest",
    initialState: {
        jobsListData: null,
        jobDetails: null,
        status: STATUSES.IDLE,
        error: null,
        // JobCreate state
        jobCreateData: null,
        jobCreateStatus: STATUSES.IDLE,
        jobCreateError: null,
    },
    extraReducers: {
        [getAllJobRequestDataRequest.pending]: (state, action) => {
            state.status = STATUSES.LOADING;
        },
        [getAllJobRequestDataRequest.fulfilled]: (state, action) => {

            state.status = STATUSES.IDLE;
            state.jobsListData = action.payload;
        },
        [getAllJobRequestDataRequest.rejected]: (state, action) => {
            state.status = STATUSES.ERROR;
            state.error = action.payload;
        },
        [jobApprovalRejectAndAcceptRequest.pending]: (state, action) => {
            state.status = STATUSES.LOADING;

        },
        [jobApprovalRejectAndAcceptRequest.fulfilled]: (state, action) => {
            state.status = STATUSES.IDLE;


        },
        [jobApprovalRejectAndAcceptRequest.rejected]: (state, action) => {
            state.status = STATUSES.ERROR;
            state.error = action.payload;
        },

        [getJobDetailsByIdRequest.pending]: (state, action) => {
            state.status = STATUSES.LOADING;

        },
        [getJobDetailsByIdRequest.fulfilled]: (state, action) => {
            state.status = STATUSES.IDLE;
            state.jobDetails = action.payload;
        },
        [getJobDetailsByIdRequest.rejected]: (state, action) => {
            state.status = STATUSES.ERROR;
            state.error = action.payload;
        },
        [postJobCreateRequest.pending]: (state, action) => {
            state.jobCreateStatus = STATUSES.LOADING;
        },
        [postJobCreateRequest.fulfilled]: (state, action) => {

            state.jobCreateStatus = STATUSES.IDLE;
            state.jobCreateData = action.payload;
        },
        [postJobCreateRequest.rejected]: (state, action) => {
            state.jobCreateStatus = STATUSES.ERROR;
            state.jobCreateError = action.payload;
        },
        [getJobStateTypeDataRequest.pending]: (state, action) => {
            state.status = STATUSES.LOADING;

        },
        [getJobStateTypeDataRequest.fulfilled]: (state, action) => {
            state.status = STATUSES.IDLE;
            state.jobDetails = action.payload;
        },
        [getJobStateTypeDataRequest.rejected]: (state, action) => {
            state.status = STATUSES.ERROR;
            state.error = action.payload;
        },
        [getJobLocationListDataRequest.pending]: (state, action) => {
            state.status = STATUSES.LOADING;

        },
        [getJobLocationListDataRequest.fulfilled]: (state, action) => {
            state.status = STATUSES.IDLE;
            state.jobDetails = action.payload;
        },
        [getJobLocationListDataRequest.rejected]: (state, action) => {
            state.status = STATUSES.ERROR;
            state.error = action.payload;
        },
        //edit job
        [getJobEditRequest.pending]: (state, action) => {
            state.status = STATUSES.LOADING;

        },
        [getJobEditRequest.fulfilled]: (state, action) => {
            state.status = STATUSES.IDLE;
            state.jobDetails = action.payload;
        },
        [getJobEditRequest.rejected]: (state, action) => {
            state.status = STATUSES.ERROR;
            state.error = action.payload;
        },


    },
});
export default JobApprovalRequestSlice.reducer;

