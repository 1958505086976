import { Form, Modal } from "antd";
import React, { useEffect, useState } from "react";
import SaveButton from "../../../components/button/saveButton";
import BaseInput from "../../../components/form/BaseInput";
import {
  editConfigurationalHospitalTypeRequest,
  getConfigurationalHospitalTypeListRequest,
} from "../../../redux/systemConfigurationSlice";
import { STATUSES } from "../../../config/constants/defaultValues";
import { useDispatch, useSelector } from "react-redux";
import Selects from "../../../components/select/Selects";
import Loading from "../../../components/shared/GeneralComponents";
import { getHospitalTypeListRequest, getStateListRequest } from "../../../redux/appDataSlice";
import { errorNotification } from "../../../components/notifications/alertNotification";

const HospitalTypeEditModal = ({ isModalOpen, setIsModalOpen, modalData }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedValues, setSelectedValues] = useState(null);

  const { StateData, StateStatus, HospitalTypeData, HospitalTypeStatus } =
    useSelector((state) => state.appData);

  useEffect(() => {
    if (modalData) {
      form.setFieldsValue({
        name: modalData?.name,
        state_id: modalData?.state.name,
        parent_id: modalData?.parent_hospital_type?.name,
      });
    }
  }, [modalData]);

  const getStateData = () => {
    if (StateStatus === STATUSES.IDLE) {
      dispatch(getStateListRequest());
    }
  };

  const getHospitalTypeData = () => {
    if (selectedValues?.state?.id) {
      dispatch(getHospitalTypeListRequest({ state: selectedValues.state.id }));
    } else {
      errorNotification("Please Select State First");
    }
  };

  const onDataChange = ({ state, hospitalType }) => {
    // Copy the existing selectedValues into a new object
    const newSelectedValues = { ...selectedValues };

    // Update only the  property in the new object

    if (state !== undefined) {
      newSelectedValues.state = state;
      newSelectedValues.hospitalType = null;
      form.resetFields(["parent_id"]);
    }

    if (hospitalType !== undefined) {
      newSelectedValues.hospitalType = hospitalType;
    }
    setSelectedValues(newSelectedValues);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  //Save Button
  const onSave = async (formData) => {
    var values = {
      ...formData,
      id: modalData?.id,
      state_id: modalData?.state.name === formData.state_id ? modalData?.state.id : formData?.state_id,
      parent_id: modalData?.parent_hospital_type.name === formData.parent_id ? modalData?.parent_hospital_type.id : formData?.parent_id,
      hospital_type_id: modalData?.id,
      status: "1",
      _method: "PUT",
    };
    setIsLoading(true);
    await dispatch(
      editConfigurationalHospitalTypeRequest({
        values,
      })
    ).then((data) => {
      if (data.payload.status === 200) {
        dispatch(getConfigurationalHospitalTypeListRequest({}));
        setIsModalOpen(false);
      }
      setIsLoading(false);
    });
  };
  return (
    <>
      <Modal
        className="modal-styles"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        maskClosable={false}
        centered={true}
        title={false}
      >
        <div className="row">
          <div className="col-md-12">
            <h5 className="table-heading mb-0 ">Edit Hospital Type</h5>
          </div>
        </div>
        <div className="modal-content">
          <Form
            layout="vertical"
            className="pt-3"
            onFinish={onSave}
            form={form}
          >
            <div className="row">
              <div className="col-sm-12 d-flex justify-content-between align-items-start">
                <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12">
                  <Selects
                    name="state_id"
                    labelText="State"
                    placeText="State"
                    notFoundContent={
                      StateStatus === STATUSES.LOADING ? (
                        <Loading height={"30vh"} />
                      ) : null
                    }
                    onSelect={(_, data) => {
                      onDataChange({
                        state: { id: data.value, name: data.children },
                      });
                    }}
                    onClear={() => {
                      onDataChange({ state: null });
                    }}
                    onClick={getStateData}
                    options={StateData?.collection_list ?? []}
                  />
                  <Selects
                    name="parent_id"
                    labelText="Parent Hospital Type"
                    placeText="Parent Hospital Type"
                    req={false}
                    disabled={selectedValues?.state != null ? false : true}
                    value={
                      selectedValues?.hospitalType == null
                        ? undefined
                        : selectedValues.hospitalType
                    }
                    notFoundContent={
                      HospitalTypeStatus === STATUSES.LOADING ? (
                        <Loading height={"30vh"} />
                      ) : null
                    }
                    onSelect={(_, data) => {
                      onDataChange({
                        hospitalType: { id: data.value, name: data.children },
                      });
                    }}
                    onClear={() => {
                      onDataChange({ hospitalType: null });
                    }}
                    onClick={getHospitalTypeData}
                    options={HospitalTypeData ?? []}
                  />
                  <BaseInput
                    inputIcon={<i className="bi bi-pencil icon_style"></i>}
                    labelText="Hospital Type"
                    name="name"
                    placeText="Add Hospital Type"
                  />
                </div>
                <SaveButton
                  loading={isLoading ? STATUSES.LOADING : false}
                  title="Save"
                />
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  );
};
export default HospitalTypeEditModal;
