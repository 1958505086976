import { Form } from "antd";
import React, { useEffect, useState, } from "react";
import SaveButton from "../../components/button/saveButton";
import BaseInput from "../../components/form/BaseInput";
import { HomeWrapped } from "./style";
import { useDispatch, useSelector } from "react-redux";
import {
  getWhatLocumTenensPageContentRequest,
  postWhatLocumTenensPageContentRequest,
} from "../../redux/contentManagementSlice";
import { BannerImage, CONTENT_PAGES, STATUSES } from "../../config/constants/defaultValues";
import Loading from "../../components/shared/GeneralComponents";
import TextAreas from "../../components/form/TextAreas";
import SeoSection from "../../components/shared/seoSection";
import BannerImageCard from "../../components/shared/bannerImageCard";

const WhatLocumTenensPage = () => {

  const dispatch = useDispatch();
  const { whatLocumTenensPageData, whatLocumTenensPageStatus, whatLocumTenensPageError } =
    useSelector((state) => state.contentManagement);
  const [form] = Form.useForm();

  const [bannerSectionImageUrl, setBannerSectionImageUrl] = useState();
  const [bannerSectionImageFile, setBannerSectionImageFile] = useState();
  // Reason Section Cards
  const [reasonsSectionFirstCardIconUrl, setReasonsSectionFirstCardIconUrl] = useState();
  const [reasonsSectionFirstCardIconFile, setReasonsSectionFirstCardIconFile] = useState();
  const [reasonsSectionSecondCardIconUrl, setReasonsSectionSecondCardIconUrl] = useState();
  const [reasonsSectionSecondCardIconFile, setReasonsSectionSecondCardIconFile] = useState();
  const [reasonsSectionThirdCardIconUrl, setReasonsSectionThirdCardIconUrl] = useState();
  const [reasonsSectionThirdCardIconFile, setReasonsSectionThirdCardIconFile] = useState();
  const [reasonsSectionForthCardIconUrl, setReasonsSectionForthCardIconUrl] = useState();
  const [reasonsSectionForthCardIconFile, setReasonsSectionForthCardIconFile] = useState();
  const [reasonsSectionFifthCardIconUrl, setReasonsSectionFifthCardIconUrl] = useState();
  const [reasonsSectionFifthCardIconFile, setReasonsSectionFifthCardIconFile] = useState();
  const [reasonsSectionSixthCardIconUrl, setReasonsSectionSixthCardIconUrl] = useState();
  const [reasonsSectionSixthCardIconFile, setReasonsSectionSixthCardIconFile] = useState();
  // How Locum Tenens Work Section Cards
  const [howLocumTenensWorkSectionFirstCardIconUrl, setHowLocumTenensWorkSectionFirstCardIconUrl] = useState();
  const [howLocumTenensWorkSectionFirstCardIconFile, setHowLocumTenensWorkSectionFirstCardIconFile] = useState();
  const [howLocumTenensWorkSectionSecondCardIconUrl, setHowLocumTenensWorkSectionSecondCardIconUrl] = useState();
  const [howLocumTenensWorkSectionSecondCardIconFile, setHowLocumTenensWorkSectionSecondCardIconFile] = useState();
  const [howLocumTenensWorkSectionThirdCardIconUrl, setHowLocumTenensWorkSectionThirdCardIconUrl] = useState();
  const [howLocumTenensWorkSectionThirdCardIconFile, setHowLocumTenensWorkSectionThirdCardIconFile] = useState();
  const [howLocumTenensWorkSectionForthCardIconUrl, setHowLocumTenensWorkSectionForthCardIconUrl] = useState();
  const [howLocumTenensWorkSectionForthCardIconFile, setHowLocumTenensWorkSectionForthCardIconFile] = useState();
  //Seo Section states
  const [ogImageUrl, setOGImageUrl] = useState();
  const [ogImageFile, setOGImageFile] = useState();
  const [twitterImageUrl, setTwitterImageUrl] = useState();
  const [twitterImageFile, setTwitterImageFile] = useState();

  useEffect(() => {
    dispatch(getWhatLocumTenensPageContentRequest(CONTENT_PAGES.WHAT_LOCUM_TENENS_PAGE));
  }, [dispatch]);

  useEffect(() => {
    if (whatLocumTenensPageData) {
      form.setFieldsValue({
        "detail[bannerSection][heading]": whatLocumTenensPageData?.contentPage?.detail?.bannerSection?.heading,
        "detail[bannerSection][description]": whatLocumTenensPageData?.contentPage?.detail?.bannerSection?.description,
        // Reasons Section
        "detail[reasonsSection][text]": whatLocumTenensPageData?.contentPage?.detail?.reasonsSection?.text,
        "detail[reasonsSection][heading]": whatLocumTenensPageData?.contentPage?.detail?.reasonsSection?.heading,
        "detail[reasonsSection][description]": whatLocumTenensPageData?.contentPage?.detail?.reasonsSection?.description,
        "detail[reasonsSection][firstCardHeading]": whatLocumTenensPageData?.contentPage?.detail?.reasonsSection?.firstCardHeading,
        "detail[reasonsSection][firstCardDescription]": whatLocumTenensPageData?.contentPage?.detail?.reasonsSection?.firstCardDescription,
        "detail[reasonsSection][secondCardHeading]": whatLocumTenensPageData?.contentPage?.detail?.reasonsSection?.secondCardHeading,
        "detail[reasonsSection][secondCardDescription]": whatLocumTenensPageData?.contentPage?.detail?.reasonsSection?.secondCardDescription,
        "detail[reasonsSection][thirdCardHeading]": whatLocumTenensPageData?.contentPage?.detail?.reasonsSection?.thirdCardHeading,
        "detail[reasonsSection][thirdCardDescription]": whatLocumTenensPageData?.contentPage?.detail?.reasonsSection?.thirdCardDescription,
        "detail[reasonsSection][forthCardHeading]": whatLocumTenensPageData?.contentPage?.detail?.reasonsSection?.forthCardHeading,
        "detail[reasonsSection][forthCardDescription]": whatLocumTenensPageData?.contentPage?.detail?.reasonsSection?.forthCardDescription,
        "detail[reasonsSection][fifthCardHeading]": whatLocumTenensPageData?.contentPage?.detail?.reasonsSection?.fifthCardHeading,
        "detail[reasonsSection][fifthCardDescription]": whatLocumTenensPageData?.contentPage?.detail?.reasonsSection?.fifthCardDescription,
        "detail[reasonsSection][sixthCardHeading]": whatLocumTenensPageData?.contentPage?.detail?.reasonsSection?.sixthCardHeading,
        "detail[reasonsSection][sixthCardDescription]": whatLocumTenensPageData?.contentPage?.detail?.reasonsSection?.sixthCardDescription,
        // Stats Section
        "detail[statsSection][heading]": whatLocumTenensPageData?.contentPage?.detail?.statsSection?.heading,
        "detail[statsSection][description]": whatLocumTenensPageData?.contentPage?.detail?.statsSection?.description,
        "detail[statsSection][firstCounterValue]": whatLocumTenensPageData?.contentPage?.detail?.statsSection?.firstCounterValue,
        "detail[statsSection][firstCounterText]": whatLocumTenensPageData?.contentPage?.detail?.statsSection?.firstCounterText,
        "detail[statsSection][secondCounterValue]": whatLocumTenensPageData?.contentPage?.detail?.statsSection?.secondCounterValue,
        "detail[statsSection][secondCounterText]": whatLocumTenensPageData?.contentPage?.detail?.statsSection?.secondCounterText,
        "detail[statsSection][thirdCounterValue]": whatLocumTenensPageData?.contentPage?.detail?.statsSection?.thirdCounterValue,
        "detail[statsSection][thirdCounterText]": whatLocumTenensPageData?.contentPage?.detail?.statsSection?.thirdCounterText,
        "detail[statsSection][forthCounterValue]": whatLocumTenensPageData?.contentPage?.detail?.statsSection?.forthCounterValue,
        "detail[statsSection][forthCounterText]": whatLocumTenensPageData?.contentPage?.detail?.statsSection?.forthCounterText,
        // how locumTenens Work
        "detail[howLocumTenensWorkSection][heading]": whatLocumTenensPageData?.contentPage?.detail?.howLocumTenensWorkSection?.heading,
        "detail[howLocumTenensWorkSection][description]": whatLocumTenensPageData?.contentPage?.detail?.howLocumTenensWorkSection?.description,
        "detail[howLocumTenensWorkSection][firstCardHeading]": whatLocumTenensPageData?.contentPage?.detail?.howLocumTenensWorkSection?.firstCardHeading,
        "detail[howLocumTenensWorkSection][firstCardDescription]": whatLocumTenensPageData?.contentPage?.detail?.howLocumTenensWorkSection?.firstCardDescription,
        "detail[howLocumTenensWorkSection][secondCardHeading]": whatLocumTenensPageData?.contentPage?.detail?.howLocumTenensWorkSection?.secondCardHeading,
        "detail[howLocumTenensWorkSection][secondCardDescription]": whatLocumTenensPageData?.contentPage?.detail?.howLocumTenensWorkSection?.secondCardDescription,
        "detail[howLocumTenensWorkSection][thirdCardHeading]": whatLocumTenensPageData?.contentPage?.detail?.howLocumTenensWorkSection?.thirdCardHeading,
        "detail[howLocumTenensWorkSection][thirdCardDescription]": whatLocumTenensPageData?.contentPage?.detail?.howLocumTenensWorkSection?.thirdCardDescription,
        "detail[howLocumTenensWorkSection][forthCardHeading]": whatLocumTenensPageData?.contentPage?.detail?.howLocumTenensWorkSection?.forthCardHeading,
        "detail[howLocumTenensWorkSection][forthCardDescription]": whatLocumTenensPageData?.contentPage?.detail?.howLocumTenensWorkSection?.forthCardDescription,
        //Seo Values Set
        meta_title: whatLocumTenensPageData?.contentPage?.seo_keywords?.meta_title ?? "",
        meta_description: whatLocumTenensPageData?.contentPage?.seo_keywords?.meta_description ?? "",
        is_canonical: whatLocumTenensPageData?.contentPage?.seo_keywords?.is_canonical === 1 ? true : false,
        robots_meta: whatLocumTenensPageData?.contentPage?.seo_keywords?.robots_meta ?? "",
        meta_author: whatLocumTenensPageData?.contentPage?.seo_keywords?.meta_author ?? "",
        og_type: whatLocumTenensPageData?.contentPage?.seo_keywords?.og_type ?? "",
        og_title: whatLocumTenensPageData?.contentPage?.seo_keywords?.og_title ?? "",
        og_description: whatLocumTenensPageData?.contentPage?.seo_keywords?.og_description ?? "",
        og_image_width: whatLocumTenensPageData?.contentPage?.seo_keywords?.og_image_width ?? "",
        og_image_height: whatLocumTenensPageData?.contentPage?.seo_keywords?.og_image_height ?? "",
        og_url: whatLocumTenensPageData?.contentPage?.seo_keywords?.og_url ?? "",
        og_site_name: whatLocumTenensPageData?.contentPage?.seo_keywords?.og_site_name ?? "",
        og_locale: whatLocumTenensPageData?.contentPage?.seo_keywords?.og_locale ?? "",
        og_locale_alternate: whatLocumTenensPageData?.contentPage?.seo_keywords?.og_locale_alternate ?? "",
        twitter_card: whatLocumTenensPageData?.contentPage?.seo_keywords?.twitter_card ?? "",
        twitter_site: whatLocumTenensPageData?.contentPage?.seo_keywords?.twitter_site ?? "",
        twitter_title: whatLocumTenensPageData?.contentPage?.seo_keywords?.twitter_title ?? "",
        twitter_description: whatLocumTenensPageData?.contentPage?.seo_keywords?.twitter_description ?? "",
        twitter_url: whatLocumTenensPageData?.contentPage?.seo_keywords?.twitter_url ?? "",
      });
      setBannerSectionImageUrl(whatLocumTenensPageData?.contentPage?.contentImages?.bannerSectionImage ?? BannerImage);
      setReasonsSectionFirstCardIconUrl(whatLocumTenensPageData?.contentPage?.contentImages?.reasonsSectionFirstCardIcon ?? BannerImage);
      setReasonsSectionSecondCardIconUrl(whatLocumTenensPageData?.contentPage?.contentImages?.reasonsSectionSecondCardIcon ?? BannerImage);
      setReasonsSectionThirdCardIconUrl(whatLocumTenensPageData?.contentPage?.contentImages?.reasonsSectionThirdCardIcon ?? BannerImage);
      setReasonsSectionForthCardIconUrl(whatLocumTenensPageData?.contentPage?.contentImages?.reasonsSectionForthCardIcon ?? BannerImage);
      setReasonsSectionFifthCardIconUrl(whatLocumTenensPageData?.contentPage?.contentImages?.reasonsSectionFifthCardIcon ?? BannerImage);
      setReasonsSectionSixthCardIconUrl(whatLocumTenensPageData?.contentPage?.contentImages?.reasonsSectionSixthCardIcon ?? BannerImage);
      setHowLocumTenensWorkSectionFirstCardIconUrl(whatLocumTenensPageData?.contentPage?.contentImages?.howLocumTenensWorkSectionFirstCardIcon ?? BannerImage);
      setHowLocumTenensWorkSectionSecondCardIconUrl(whatLocumTenensPageData?.contentPage?.contentImages?.howLocumTenensWorkSectionSecondCardIcon ?? BannerImage);
      setHowLocumTenensWorkSectionThirdCardIconUrl(whatLocumTenensPageData?.contentPage?.contentImages?.howLocumTenensWorkSectionThirdCardIcon ?? BannerImage);
      setHowLocumTenensWorkSectionForthCardIconUrl(whatLocumTenensPageData?.contentPage?.contentImages?.howLocumTenensWorkSectionForthCardIcon ?? BannerImage);
      setTwitterImageUrl(whatLocumTenensPageData?.contentPage?.seo_keywords?.twitter_image ?? BannerImage);
      setOGImageUrl(whatLocumTenensPageData?.contentPage?.seo_keywords?.og_image ?? BannerImage);
    }
  }, [whatLocumTenensPageData]);

  const onUpdate = (values) => {

    const data = {
      ...values,
      name: 'What Locum Tenens',
      "og_image": ogImageFile ?? "",
      "twitter_image": twitterImageFile ?? "",
      "is_canonical": values.is_canonical === true ? 1 : 0,
      "contentImages[bannerSectionImage]": bannerSectionImageFile ?? "",
      "contentImages[reasonsSectionFirstCardIcon]": reasonsSectionFirstCardIconFile ?? "",
      "contentImages[reasonsSectionSecondCardIcon]": reasonsSectionSecondCardIconFile ?? "",
      "contentImages[reasonsSectionThirdCardIcon]": reasonsSectionThirdCardIconFile ?? "",
      "contentImages[reasonsSectionForthCardIcon]": reasonsSectionForthCardIconFile ?? "",
      "contentImages[reasonsSectionFifthCardIcon]": reasonsSectionFifthCardIconFile ?? "",
      "contentImages[reasonsSectionSixthCardIcon]": reasonsSectionSixthCardIconFile ?? "",
      "contentImages[howLocumTenensWorkSectionFirstCardIcon]": howLocumTenensWorkSectionFirstCardIconFile ?? "",
      "contentImages[howLocumTenensWorkSectionSecondCardIcon]": howLocumTenensWorkSectionSecondCardIconFile ?? "",
      "contentImages[howLocumTenensWorkSectionThirdCardIcon]": howLocumTenensWorkSectionThirdCardIconFile ?? "",
      "contentImages[howLocumTenensWorkSectionForthCardIcon]": howLocumTenensWorkSectionForthCardIconFile ?? "",
      _method: 'PUT'
    }
    dispatch(postWhatLocumTenensPageContentRequest({ data, id: whatLocumTenensPageData?.contentPage?.id }))
  };

  return (
    <>
      {whatLocumTenensPageStatus === STATUSES.LOADING ? (
        <Loading />
      ) : whatLocumTenensPageStatus === STATUSES.ERROR ? (
        <div>{whatLocumTenensPageError}</div>
      ) : (
        <HomeWrapped>
          <div className="row">
            <div className="col-sm-12">
              <Form
                layout="vertical"
                className="pt-3"
                form={form}
                onFinish={onUpdate}

              >
                <div className="row">
                  <div className="col-sm-12 d-flex justify-content-between align-items-start mb-4">
                    <h3 className="font-bold" >What Locum Tenens</h3>
                    <SaveButton title="Update" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-9 col-lg-12 col-md-12 col-sm-12">
                    {/* Banner Section */}
                    {/* <HeadingTitle title="Banner Section" /> */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Banner Section Heading"
                      name="detail[bannerSection][heading]"
                      placeText="Banner Section Heading"
                    />
                    <TextAreas
                      labelText="Banner Section Description"
                      name="detail[bannerSection][description]"
                      placeText="Banner Section Description"
                    />
                    <BannerImageCard
                      title="Banner Section Image"
                      imageUrl={bannerSectionImageUrl}
                      isBlogImage={true}
                      setImageFile={setBannerSectionImageFile}
                      setImageUrl={setBannerSectionImageUrl}
                      imageText="Change Image"
                    />
                    {/* Reasons Section */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Reasons Section Text"
                      name="detail[reasonsSection][text]"
                      placeText="Reasons Section Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Reasons Section Heading"
                      name="detail[reasonsSection][heading]"
                      placeText="Reasons Section Heading"
                    />
                    <TextAreas
                      labelText="Reasons Section Description"
                      name="detail[reasonsSection][description]"
                      placeText="Reasons Section Description"
                    />
                    <BannerImageCard
                      title="Reasons Section First Card Icon"
                      imageUrl={reasonsSectionFirstCardIconUrl}
                      isBlogImage={true}
                      setImageFile={setReasonsSectionFirstCardIconFile}
                      setImageUrl={setReasonsSectionFirstCardIconUrl}
                      imageText="Change Icon"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Reasons Section First Card Heading"
                      name="detail[reasonsSection][firstCardHeading]"
                      placeText="Reasons Section First Card Heading"
                    />
                    <TextAreas
                      labelText="Reasons Section First Card Description"
                      name="detail[reasonsSection][firstCardDescription]"
                      placeText="Reasons Section First Card Description"
                    />
                    <BannerImageCard
                      title="Reasons Section Second Card Icon"
                      imageUrl={reasonsSectionSecondCardIconUrl}
                      isBlogImage={true}
                      setImageFile={setReasonsSectionSecondCardIconFile}
                      setImageUrl={setReasonsSectionSecondCardIconUrl}
                      imageText="Change Icon"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Reasons Section Second Card Heading"
                      name="detail[reasonsSection][secondCardHeading]"
                      placeText="Reasons Section Second Card Heading"
                    />
                    <TextAreas
                      labelText="Reasons Section Second Card Description"
                      name="detail[reasonsSection][secondCardDescription]"
                      placeText="Reasons Section Second Card Description"
                    />
                    <BannerImageCard
                      title="Reasons Section Third Card Icon"
                      imageUrl={reasonsSectionThirdCardIconUrl}
                      isBlogImage={true}
                      setImageFile={setReasonsSectionThirdCardIconFile}
                      setImageUrl={setReasonsSectionThirdCardIconUrl}
                      imageText="Change Icon"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Reasons Section Third Card Heading"
                      name="detail[reasonsSection][thirdCardHeading]"
                      placeText="Reasons Section Third Card Heading"
                    />
                    <TextAreas
                      labelText="Reasons Section Third Card Description"
                      name="detail[reasonsSection][thirdCardDescription]"
                      placeText="Reasons Section Third Card Description"
                    />
                    <BannerImageCard
                      title="Reasons Section Forth Card Icon"
                      imageUrl={reasonsSectionForthCardIconUrl}
                      isBlogImage={true}
                      setImageFile={setReasonsSectionForthCardIconFile}
                      setImageUrl={setReasonsSectionForthCardIconUrl}
                      imageText="Change Icon"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Reasons Section Forth Card Heading"
                      name="detail[reasonsSection][forthCardHeading]"
                      placeText="Reasons Section Forth Card Heading"
                    />
                    <TextAreas
                      labelText="Reasons Section Forth Card Description"
                      name="detail[reasonsSection][forthCardDescription]"
                      placeText="Reasons Section Forth Card Description"
                    />
                    <BannerImageCard
                      title="Reasons Section Fifth Card Icon"
                      imageUrl={reasonsSectionFifthCardIconUrl}
                      isBlogImage={true}
                      setImageFile={setReasonsSectionFifthCardIconFile}
                      setImageUrl={setReasonsSectionFifthCardIconUrl}
                      imageText="Change Icon"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Reasons Section Fifth Card Heading"
                      name="detail[reasonsSection][fifthCardHeading]"
                      placeText="Reasons Section Fifth Card Heading"
                    />
                    <TextAreas
                      labelText="Reasons Section Fifth Card Description"
                      name="detail[reasonsSection][fifthCardDescription]"
                      placeText="Reasons Section Fifth Card Description"
                    />
                    <BannerImageCard
                      title="Reasons Section Sixth Card Icon"
                      imageUrl={reasonsSectionSixthCardIconUrl}
                      isBlogImage={true}
                      setImageFile={setReasonsSectionSixthCardIconFile}
                      setImageUrl={setReasonsSectionSixthCardIconUrl}
                      imageText="Change Icon"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Reasons Section Sixth Card Heading"
                      name="detail[reasonsSection][sixthCardHeading]"
                      placeText="Reasons Section Sixth Card Heading"
                    />
                    <TextAreas
                      labelText="Reasons Section Sixth Card Description"
                      name="detail[reasonsSection][sixthCardDescription]"
                      placeText="Reasons Section Sixth Card Description"
                    />
                    {/* Stats Section */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Stats Section Heading"
                      name="detail[statsSection][heading]"
                      placeText="Stats Section Heading"
                    />
                    <TextAreas
                      labelText="Stats Section Sixth Card Description"
                      name="detail[statsSection][description]"
                      placeText="Stats Section Sixth Card Description"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Stats Section First Counter Value"
                      name="detail[statsSection][firstCounterValue]"
                      placeText="Stats Section First Counter Value"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Stats Section First Counter Text"
                      name="detail[statsSection][firstCounterText]"
                      placeText="Stats Section First Counter Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Stats Section Second Counter Value"
                      name="detail[statsSection][secondCounterValue]"
                      placeText="Stats Section Second Counter Value"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Stats Section Second Counter Text"
                      name="detail[statsSection][secondCounterText]"
                      placeText="Stats Section Second Counter Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Stats Section Third Counter Value"
                      name="detail[statsSection][thirdCounterValue]"
                      placeText="Stats Section Third Counter Value"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Stats Section Third Counter Text"
                      name="detail[statsSection][thirdCounterText]"
                      placeText="Stats Section Third Counter Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Stats Section Forth Counter Value"
                      name="detail[statsSection][forthCounterValue]"
                      placeText="Stats Section Forth Counter Value"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Stats Section Forth Counter Text"
                      name="detail[statsSection][forthCounterText]"
                      placeText="Stats Section Forth Counter Text"
                    />
                    {/* How Locum Tenens Work Section */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="How Locum Tenens Work Section Heading"
                      name="detail[howLocumTenensWorkSection][heading]"
                      placeText="How Locum Tenens Work Section Heading"
                    />
                    <TextAreas
                      labelText="How Locum Tenens Work Section Description"
                      name="detail[howLocumTenensWorkSection][description]"
                      placeText="How Locum Tenens Work Section Description"
                    />
                    <BannerImageCard
                      title="How Locum Tenens Work Section First Card Icon"
                      imageUrl={howLocumTenensWorkSectionFirstCardIconUrl}
                      isBlogImage={true}
                      setImageFile={setHowLocumTenensWorkSectionFirstCardIconFile}
                      setImageUrl={setHowLocumTenensWorkSectionFirstCardIconUrl}
                      imageText="Change Icon"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="How Locum Tenens Work Section First Card Heading"
                      name="detail[howLocumTenensWorkSection][firstCardHeading]"
                      placeText="How Locum Tenens Work Section First Card Heading"
                    />
                    <TextAreas
                     labelText="How Locum Tenens Work Section First Card Description"
                     name="detail[howLocumTenensWorkSection][firstCardDescription]"
                     placeText="How Locum Tenens Work Section First Card Description"
                    />
                    <BannerImageCard
                      title="How Locum Tenens Work Section Second Card Icon"
                      imageUrl={howLocumTenensWorkSectionSecondCardIconUrl}
                      isBlogImage={true}
                      setImageFile={setHowLocumTenensWorkSectionSecondCardIconFile}
                      setImageUrl={setHowLocumTenensWorkSectionSecondCardIconUrl}
                      imageText="Change Icon"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="How Locum Tenens Work Section Second Card Heading"
                      name="detail[howLocumTenensWorkSection][secondCardHeading]"
                      placeText="How Locum Tenens Work Section Second Card Heading"
                    />
                    <TextAreas
                     labelText="How Locum Tenens Work Section Second Card Description"
                     name="detail[howLocumTenensWorkSection][secondCardDescription]"
                     placeText="How Locum Tenens Work Section Second Card Description"
                    />
                    <BannerImageCard
                      title="How Locum Tenens Work Section Third Card Icon"
                      imageUrl={howLocumTenensWorkSectionThirdCardIconUrl}
                      isBlogImage={true}
                      setImageFile={setHowLocumTenensWorkSectionThirdCardIconFile}
                      setImageUrl={setHowLocumTenensWorkSectionThirdCardIconUrl}
                      imageText="Change Icon"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="How Locum Tenens Work Section Third Card Heading"
                      name="detail[howLocumTenensWorkSection][thirdCardHeading]"
                      placeText="How Locum Tenens Work Section Third Card Heading"
                    />
                    <TextAreas
                     labelText="How Locum Tenens Work Section Third Card Description"
                     name="detail[howLocumTenensWorkSection][thirdCardDescription]"
                     placeText="How Locum Tenens Work Section Third Card Description"
                    />
                    <BannerImageCard
                      title="How Locum Tenens Work Section Forth Card Icon"
                      imageUrl={howLocumTenensWorkSectionForthCardIconUrl}
                      isBlogImage={true}
                      setImageFile={setHowLocumTenensWorkSectionForthCardIconFile}
                      setImageUrl={setHowLocumTenensWorkSectionForthCardIconUrl}
                      imageText="Change Icon"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="How Locum Tenens Work Section Forth Card Heading"
                      name="detail[howLocumTenensWorkSection][forthCardHeading]"
                      placeText="How Locum Tenens Work Section Forth Card Heading"
                    />
                    <TextAreas
                     labelText="How Locum Tenens Work Section Forth Card Description"
                     name="detail[howLocumTenensWorkSection][forthCardDescription]"
                     placeText="How Locum Tenens Work Section Forth Card Description"
                    />
                  </div>
                  <SeoSection ogImageUrl={ogImageUrl}
                    setOGImageFile={setOGImageFile}
                    setOGImageUrl={setOGImageUrl}
                    twitterImageUrl={twitterImageUrl}
                    setTwitterImageFile={setTwitterImageFile}
                    setTwitterImageUrl={setTwitterImageUrl} />
                </div>
              </Form>
            </div>
          </div>
        </HomeWrapped>
      )}
    </>
  );
};

export default WhatLocumTenensPage;
