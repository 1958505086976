import { LeftOutlined, MailOutlined, RightOutlined, UserOutlined, UsergroupAddOutlined } from "@ant-design/icons";
import { Menu } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { ReactComponent as AutoIcon } from "../../assets/images/icon/autoIcon.svg";
import { ReactComponent as ContentIcon } from "../../assets/images/icon/contentIcon.svg";
import { ReactComponent as DashboardIcon } from "../../assets/images/icon/dashboardIcon.svg";
import { ReactComponent as JobIcon } from "../../assets/images/icon/jobIcon.svg";
import { ReactComponent as SettingIcon } from "../../assets/images/icon/setting.svg";
import { USER_TYPE } from "../../config/constants/defaultValues";

function Sidenav({ color, onMenuClick, sideNavOpen, }) {
  const iconSize = sideNavOpen ? 20 : 16;
  const { userType } = useSelector((state) => state.auth);
  const { pathname } = useLocation();
  const page = pathname.replace("/", "");
  // console.log("user data", userType)
  const location = useLocation();


  const currentPath = window.location.pathname;
  const isUserListMenu = currentPath.startsWith('/user-list');
  const isJobListMenu = currentPath.startsWith('/job-list') || currentPath.startsWith('/job-create');


  const [selectedKeys, setSelectedKeys] = useState(['1']);
  useEffect(() => {
    console.log('useEffect is running');
    const storedKey = localStorage.getItem('selectedKey');
    if (storedKey) {
      setSelectedKeys([storedKey]);
    }
    handleMenuClick({ key: storedKey || '1' });
  }, []);



  const handleMenuClick = (e) => {
    setSelectedKeys([e.key]);
    localStorage.setItem('selectedKey', e.key);
  };


  return (
    <>
      <div className="d-none d-lg-block">
        {sideNavOpen ? (
          <span
            className="icon m-right"
            onClick={onMenuClick}
          >
            {/* <MenuIcon width="25" height="25" className={`svg-icon ${sideNavOpen ? 'secondary-clr' : 'primary-clr'} `} /> */}
            {/* <MenuIcon width="25" height="25" className="menu-icon-clr" /> */}
            {/* <MenuOutlined width="40" height="40" className="menu-icon-clr" /> */}
            <RightOutlined width="40" height="40" className="menu-icon-clr" />
          </span>
        ) : (
          <span
            className="icon close-sidebar"
            onClick={onMenuClick}
          >
            <LeftOutlined width="30" height="30" className="primaryClr" />
          </span>
        )
        }
      </div>
      <Menu theme="light" mode="inline" defaultSelectedKeys={["1"]} selectedKeys={selectedKeys}
        onClick={handleMenuClick} className="sidebar-main">

        {
          <Menu.Item key="1">
            <NavLink to="/dashboard">
              <span className={`icon ${sideNavOpen ? 'collapsed-icon' : ''}`}>
                <DashboardIcon width={iconSize} height={iconSize}
                  className={`svg-icon ${(!sideNavOpen && !selectedKeys.includes('1')) ? 'collapsed-clr-open-selected' :
                    (!sideNavOpen && !selectedKeys.includes('1')) ? 'collapsed-clr-open-unSelected' :
                      (sideNavOpen && selectedKeys.includes('1')) ? 'collapsed-clr-close-selected' :
                        (sideNavOpen && !selectedKeys.includes('1')) ? 'collapsed-clr-close-unSelected' :
                          'collapsed-clr-open-unSelected'} `} />
              </span>
              {!sideNavOpen && <span className="label">Dashboard</span>}
            </NavLink>
          </Menu.Item>
        }

        {(userType === USER_TYPE.SUPER_ADMIN ||
          userType === USER_TYPE.JUMP_ADMIN) && (
            <Menu.Item key="3">
              <NavLink to="/job-list" className={isJobListMenu ? 'active' : ''}>
                <span className={`icon ${sideNavOpen ? 'collapsed-icon' : ''}`}>

                  <JobIcon width={iconSize} height={iconSize} className={`svg-icon ${(!sideNavOpen && !selectedKeys.includes('3')) ? 'collapsed-clr-open-selected' :
                    (!sideNavOpen && !selectedKeys.includes('3')) ? 'collapsed-clr-open-unSelected' :
                      (sideNavOpen && selectedKeys.includes('3')) ? 'collapsed-clr-close-selected' :
                        (sideNavOpen && !selectedKeys.includes('3')) ? 'collapsed-clr-close-unSelected' :
                          'collapsed-clr-open-unSelected'}`} />
                </span>
                {!sideNavOpen && <span className="label">Jobs</span>}
              </NavLink>
            </Menu.Item>
          )}
        {(userType === USER_TYPE.SUPER_ADMIN ||
          userType === USER_TYPE.JUMP_ADMIN) && (
            <Menu.Item key="4">
              <NavLink to="/user-list">
                <span
                  className={`icon  ${sideNavOpen ? 'collapsed-icon' : ''}`}
                >
                  <UserOutlined width={iconSize} height={iconSize} className={`svg-icon ${(!sideNavOpen && !selectedKeys.includes('4')) ? 'collapsed-clr-open-selected' :
                    (!sideNavOpen && !selectedKeys.includes('4')) ? 'collapsed-clr-open-unSelected' :
                      (sideNavOpen && selectedKeys.includes('4')) ? 'collapsed-clr-close-selected' :
                        (sideNavOpen && !selectedKeys.includes('4')) ? 'collapsed-clr-close-unSelected' :
                          'collapsed-clr-open-unSelected'}`} />
                  {/* <UserOutlined /> */}
                </span>
                {!sideNavOpen && <span className="label">Website Users</span>}
              </NavLink>
            </Menu.Item>
          )}
        {(userType === USER_TYPE.SUPER_ADMIN ||
          userType === USER_TYPE.JUMP_ADMIN) && (
            <Menu.Item key="5">
              <NavLink to="/job-applications">
                <span className={`icon  ${sideNavOpen ? 'collapsed-icon' : ''}`}>
                  <JobIcon width={iconSize} height={iconSize} className={`svg-icon ${(!sideNavOpen && !selectedKeys.includes('5')) ? 'collapsed-clr-open-selected' :
                    (!sideNavOpen && !selectedKeys.includes('5')) ? 'collapsed-clr-open-unSelected' :
                      (sideNavOpen && selectedKeys.includes('5')) ? 'collapsed-clr-close-selected' :
                        (sideNavOpen && !selectedKeys.includes('5')) ? 'collapsed-clr-close-unSelected' :
                          'collapsed-clr-open-unSelected'}`} />
                </span>
                {!sideNavOpen && <span className="label">Jobs Applied List</span>}
              </NavLink>
            </Menu.Item>
          )}
        {(userType === USER_TYPE.SUPER_ADMIN ||
          userType === USER_TYPE.JUMP_ADMIN) && (
            <Menu.Item key="9">
              <NavLink to="/referral-list">
                <span className={`icon  ${sideNavOpen ? 'collapsed-icon' : ''}`}>
                  <UsergroupAddOutlined width={iconSize} height={iconSize} className={`svg-icon ${(!sideNavOpen && !selectedKeys.includes('9')) ? 'collapsed-clr-open-selected' :
                    (!sideNavOpen && !selectedKeys.includes('9')) ? 'collapsed-clr-open-unSelected' :
                      (sideNavOpen && selectedKeys.includes('9')) ? 'collapsed-clr-close-selected' :
                        (sideNavOpen && !selectedKeys.includes('9')) ? 'collapsed-clr-close-unSelected' :
                          'collapsed-clr-open-unSelected'}`} />
                </span>
                {!sideNavOpen && <span className="label">Referral User List</span>}
              </NavLink>
            </Menu.Item>
          )}
        {(userType === USER_TYPE.SUPER_ADMIN ||
          userType === USER_TYPE.JUMP_ADMIN) && (
            <Menu.Item key="6">
              <NavLink to="/contact-form-list">
                <span
                  className={`icon  ${sideNavOpen ? 'collapsed-icon' : ''}`}
                >
                  <AutoIcon width={iconSize} height={iconSize} className={`svg-icon ${(!sideNavOpen && !selectedKeys.includes('6')) ? 'collapsed-clr-open-selected' :
                    (!sideNavOpen && !selectedKeys.includes('6')) ? 'collapsed-clr-open-unSelected' :
                      (sideNavOpen && selectedKeys.includes('6')) ? 'collapsed-clr-close-selected' :
                        (sideNavOpen && !selectedKeys.includes('6')) ? 'collapsed-clr-close-unSelected' :
                          'collapsed-clr-open-unSelected'}`} />
                </span>
                {!sideNavOpen && <span className="label">Contact Form List</span>}
              </NavLink>
            </Menu.Item>
          )}
        {(userType === USER_TYPE.SUPER_ADMIN ||
          userType === USER_TYPE.JUMP_ADMIN) && (
            <Menu.Item key="7">
              <NavLink to="/email-contact-list">
                <span
                  className={`icon  ${sideNavOpen ? 'collapsed-icon' : ''}`}
                >
                  <MailOutlined width={iconSize} height={iconSize} className={`svg-icon ${(!sideNavOpen && !selectedKeys.includes('7')) ? 'collapsed-clr-open-selected' :
                    (!sideNavOpen && !selectedKeys.includes('7')) ? 'collapsed-clr-open-unSelected' :
                      (sideNavOpen && selectedKeys.includes('7')) ? 'collapsed-clr-close-selected' :
                        (sideNavOpen && !selectedKeys.includes('7')) ? 'collapsed-clr-close-unSelected' :
                          'collapsed-clr-open-unSelected'}`} />
                </span>
                {!sideNavOpen && <span className="label">Email Contact List</span>}
              </NavLink>
            </Menu.Item>
          )}
        {(userType === USER_TYPE.SUPER_ADMIN ||
          userType === USER_TYPE.SYSTEM_ADMIN) && (
            <Menu.Item key="2">
              <NavLink className="item-icon" to="/content-management">
                <span className={`icon  ${sideNavOpen ? 'collapsed-icon' : ''}`} >
                  <ContentIcon width={iconSize} height={iconSize} className={`svg-icon ${(!sideNavOpen && !selectedKeys.includes('2')) ? 'collapsed-clr-open-selected' :
                    (!sideNavOpen && !selectedKeys.includes('2')) ? 'collapsed-clr-open-unSelected' :
                      (sideNavOpen && selectedKeys.includes('2')) ? 'collapsed-clr-close-selected' :
                        (sideNavOpen && !selectedKeys.includes('2')) ? 'collapsed-clr-close-unSelected' :
                          'collapsed-clr-open-unSelected'}`} />
                </span>
                {!sideNavOpen && <span className="label">Content Management</span>}
              </NavLink>
            </Menu.Item>
          )}
        {(userType === USER_TYPE.SUPER_ADMIN ||
          userType === USER_TYPE.SYSTEM_ADMIN) && (
            <Menu.Item key="8">
              <NavLink className="item-icon" to="/system-configuration">
                <span className={`icon  ${sideNavOpen ? 'collapsed-icon' : ''}`}>
                  <SettingIcon width={iconSize} height={iconSize} className={`svg-icon ${(!sideNavOpen && !selectedKeys.includes('8')) ? 'collapsed-clr-open-selected' :
                    (!sideNavOpen && !selectedKeys.includes('8')) ? 'collapsed-clr-open-unSelected' :
                      (sideNavOpen && selectedKeys.includes('8')) ? 'collapsed-clr-close-selected' :
                        (sideNavOpen && !selectedKeys.includes('8')) ? 'collapsed-clr-close-unSelected' :
                          'collapsed-clr-open-unSelected'}`} />
                </span>
                {!sideNavOpen && <span className="label">System Configuration</span>}
                <span
                  className={`icon  ${sideNavOpen ? 'collapsed-icon' : ''}`}
                  style={{
                    background: page === "tables" ? color : "",
                    marginRight: "0px",
                    paddingLeft: "5px",
                  }}
                >
                  {!sideNavOpen && <RightOutlined width={iconSize} height={iconSize} />}
                </span>
              </NavLink>
            </Menu.Item>
          )}
      </Menu>
    </>
  );
}

export default Sidenav;
