import { Form } from "antd";
import React, { useEffect, useState } from "react";
import SaveButton from "../../components/button/saveButton";
import BaseInput from "../../components/form/BaseInput";
import { HomeWrapped } from "./style";
import { useDispatch, useSelector } from "react-redux";
import {
  getHomeContentPageContentRequest,
  postHomeContentPageContentRequest,
} from "../../redux/contentManagementSlice";
import { BannerImage, CONTENT_PAGES, STATUSES } from "../../config/constants/defaultValues";
import Loading from "../../components/shared/GeneralComponents";
import TextAreas from "../../components/form/TextAreas";
import SeoSection from "../../components/shared/seoSection";
import BannerImageCard from "../../components/shared/bannerImageCard";
import VideoCard from "../../components/shared/VideoCard";

const HomeContentPage = () => {
  const dispatch = useDispatch();
  const { homeContentPageData, homeContentPageStatus, homeContentPageError } =
    useSelector((state) => state.contentManagement);
  const [form] = Form.useForm();
  //Seo Section states
  const [ogImageUrl, setOGImageUrl] = useState();
  const [ogImageFile, setOGImageFile] = useState();
  const [twitterImageUrl, setTwitterImageUrl] = useState();
  const [twitterImageFile, setTwitterImageFile] = useState();
  // Banner Video Home Page
  const [bannerSectionVideoUrl, setBannerSectionVideoUrl] = useState();
  const [bannerSectionVideoFile, setBannerSectionVideoFile] = useState();
  // About Section Images
  const [aboutSectionImageUrl, setAboutSectionImageUrl] = useState();
  const [aboutSectionImageFile, setAboutSectionImageFile] = useState();
  // why Choose Section
  const [whyChooseSectionImageUrl, setWhyChooseSectionImageUrl] = useState();
  const [whyChooseSectionImageFile, setWhyChooseSectionImageFile] = useState();  
  // Meet Providers Section Images
  const [meetProviderSectionFirstProviderThumbnailUrl, setMeetProviderSectionFirstProviderThumbnailUrl] = useState();
  const [meetProviderSectionFirstProviderThumbnailFile, setMeetProviderSectionFirstProviderThumbnailFile] = useState();
  const [meetProviderSectionSecondProviderThumbnailUrl, setMeetProviderSectionSecondProviderThumbnailUrl] = useState();
  const [meetProviderSectionSecondProviderThumbnailFile, setMeetProviderSectionSecondProviderThumbnailFile] = useState();
  const [meetProviderSectionThirdProviderThumbnailUrl, setMeetProviderSectionThirdProviderThumbnailUrl] = useState();
  const [meetProviderSectionThirdProviderThumbnailFile, setMeetProviderSectionThirdProviderThumbnailFile] = useState();
  const [meetProviderSectionFirstProviderVideoUrl, setMeetProviderSectionFirstProviderVideoUrl] = useState();
  const [meetProviderSectionFirstProviderVideoFile, setMeetProviderSectionFirstProviderVideoFile] = useState();
  const [meetProviderSectionSecondProviderVideoUrl, setMeetProviderSectionSecondProviderVideoUrl] = useState();
  const [meetProviderSectionSecondProviderVideoFile, setMeetProviderSectionSecondProviderVideoFile] = useState();
  const [meetProviderSectionThirdProviderVideoUrl, setMeetProviderSectionThirdProviderVideoUrl] = useState();
  const [meetProviderSectionThirdProviderVideoFile, setMeetProviderSectionThirdProviderVideoFile] = useState();
  useEffect(() => {
    dispatch(getHomeContentPageContentRequest(CONTENT_PAGES.HOME_PAGE));
  }, [dispatch]);

  useEffect(() => {
    if (homeContentPageData) {
      form.setFieldsValue({
        "detail[bannerSection][heading]": homeContentPageData?.contentPage?.detail?.bannerSection?.heading,
        "detail[bannerSection][description]": homeContentPageData?.contentPage?.detail?.bannerSection?.description,
        "detail[bannerSection][searchButtonText]": homeContentPageData?.contentPage?.detail?.bannerSection?.searchButtonText,
        "detail[bannerSection][firstCounterValue]": homeContentPageData?.contentPage?.detail?.bannerSection?.firstCounterValue,
        "detail[bannerSection][firstCounterText]": homeContentPageData?.contentPage?.detail?.bannerSection?.firstCounterText,
        "detail[bannerSection][secondCounterValue]": homeContentPageData?.contentPage?.detail?.bannerSection?.secondCounterValue,
        "detail[bannerSection][secondCounterText]": homeContentPageData?.contentPage?.detail?.bannerSection?.secondCounterText,
        "detail[bannerSection][thirdCounterValue]": homeContentPageData?.contentPage?.detail?.bannerSection?.thirdCounterValue,
        "detail[bannerSection][thirdCounterText]": homeContentPageData?.contentPage?.detail?.bannerSection?.thirdCounterText,
        "detail[bannerSection][forthCounterValue]": homeContentPageData?.contentPage?.detail?.bannerSection?.forthCounterValue,
        "detail[bannerSection][forthCounterText]": homeContentPageData?.contentPage?.detail?.bannerSection?.forthCounterText,
        // Feature Job Section
        "detail[featureJobSection][text]": homeContentPageData?.contentPage?.detail?.featureJobSection?.text,
        "detail[featureJobSection][heading]": homeContentPageData?.contentPage?.detail?.featureJobSection?.heading,
        "detail[featureJobSection][description]": homeContentPageData?.contentPage?.detail?.featureJobSection?.description,
        "detail[featureJobSection][buttonText]": homeContentPageData?.contentPage?.detail?.featureJobSection?.buttonText,
        // About Us Section
        "detail[aboutSection][text]": homeContentPageData?.contentPage?.detail?.aboutSection?.text,
        "detail[aboutSection][heading]": homeContentPageData?.contentPage?.detail?.aboutSection?.heading,
        "detail[aboutSection][description]": homeContentPageData?.contentPage?.detail?.aboutSection?.description,
        "detail[aboutSection][firstBulletPoint]": homeContentPageData?.contentPage?.detail?.aboutSection?.firstBulletPoint,
        "detail[aboutSection][secondBulletPoint]": homeContentPageData?.contentPage?.detail?.aboutSection?.secondBulletPoint,
        "detail[aboutSection][thirdBulletPoint]": homeContentPageData?.contentPage?.detail?.aboutSection?.thirdBulletPoint,
        "detail[aboutSection][forthBulletPoint]": homeContentPageData?.contentPage?.detail?.aboutSection?.forthBulletPoint,
        "detail[aboutSection][buttonText]": homeContentPageData?.contentPage?.detail?.aboutSection?.buttonText,
        // Why Choose Section
        "detail[whyChooseSection][text]": homeContentPageData?.contentPage?.detail?.whyChooseSection?.text,
        "detail[whyChooseSection][heading]": homeContentPageData?.contentPage?.detail?.whyChooseSection?.heading,
        "detail[whyChooseSection][description]": homeContentPageData?.contentPage?.detail?.whyChooseSection?.description,
        "detail[whyChooseSection][firstSubHeading]": homeContentPageData?.contentPage?.detail?.whyChooseSection?.firstSubHeading,
        "detail[whyChooseSection][firstSubDescription]": homeContentPageData?.contentPage?.detail?.whyChooseSection?.firstSubDescription,
        "detail[whyChooseSection][secondSubHeading]": homeContentPageData?.contentPage?.detail?.whyChooseSection?.secondSubHeading,
        "detail[whyChooseSection][secondSubDescription]": homeContentPageData?.contentPage?.detail?.whyChooseSection?.secondSubDescription,
        "detail[whyChooseSection][thirdSubHeading]": homeContentPageData?.contentPage?.detail?.whyChooseSection?.thirdSubHeading,
        "detail[whyChooseSection][thirdSubDescription]": homeContentPageData?.contentPage?.detail?.whyChooseSection?.thirdSubDescription,
        // Testimonial Section
        "detail[testimonialsSection][text]": homeContentPageData?.contentPage?.detail?.testimonialsSection?.text,
        "detail[testimonialsSection][heading]": homeContentPageData?.contentPage?.detail?.testimonialsSection?.heading,
        "detail[testimonialsSection][description]": homeContentPageData?.contentPage?.detail?.testimonialsSection?.description,
        // Meet Providers Section
        "detail[meetOurProvidersSection][text]": homeContentPageData?.contentPage?.detail?.meetOurProvidersSection?.text,
        "detail[meetOurProvidersSection][heading]": homeContentPageData?.contentPage?.detail?.meetOurProvidersSection?.heading,
        "detail[meetOurProvidersSection][description]": homeContentPageData?.contentPage?.detail?.meetOurProvidersSection?.description,
        "detail[meetOurProvidersSection][firstProviderName]": homeContentPageData?.contentPage?.detail?.meetOurProvidersSection?.firstProviderName,
        "detail[meetOurProvidersSection][firstProviderJobTitle]": homeContentPageData?.contentPage?.detail?.meetOurProvidersSection?.firstProviderJobTitle,
        "detail[meetOurProvidersSection][secondProviderName]": homeContentPageData?.contentPage?.detail?.meetOurProvidersSection?.secondProviderName,
        "detail[meetOurProvidersSection][secondProviderJobTitle]": homeContentPageData?.contentPage?.detail?.meetOurProvidersSection?.secondProviderJobTitle,
        "detail[meetOurProvidersSection][thirdProviderName]": homeContentPageData?.contentPage?.detail?.meetOurProvidersSection?.thirdProviderName,
        "detail[meetOurProvidersSection][thirdProviderJobTitle]": homeContentPageData?.contentPage?.detail?.meetOurProvidersSection?.thirdProviderJobTitle,

        //Seo Values Set
        meta_title: homeContentPageData?.contentPage?.seo_keywords?.meta_title ?? "",
        meta_description: homeContentPageData?.contentPage?.seo_keywords?.meta_description ?? "",
        is_canonical: homeContentPageData?.contentPage?.seo_keywords?.is_canonical === 1 ? true : false,
        robots_meta: homeContentPageData?.contentPage?.seo_keywords?.robots_meta ?? "",
        meta_author: homeContentPageData?.contentPage?.seo_keywords?.meta_author ?? "",
        og_type: homeContentPageData?.contentPage?.seo_keywords?.og_type ?? "",
        og_title: homeContentPageData?.contentPage?.seo_keywords?.og_title ?? "",
        og_description: homeContentPageData?.contentPage?.seo_keywords?.og_description ?? "",
        og_image_width: homeContentPageData?.contentPage?.seo_keywords?.og_image_width ?? "",
        og_image_height: homeContentPageData?.contentPage?.seo_keywords?.og_image_height ?? "",
        og_url: homeContentPageData?.contentPage?.seo_keywords?.og_url ?? "",
        og_site_name: homeContentPageData?.contentPage?.seo_keywords?.og_site_name ?? "",
        og_locale: homeContentPageData?.contentPage?.seo_keywords?.og_locale ?? "",
        og_locale_alternate: homeContentPageData?.contentPage?.seo_keywords?.og_locale_alternate ?? "",
        twitter_card: homeContentPageData?.contentPage?.seo_keywords?.twitter_card ?? "",
        twitter_site: homeContentPageData?.contentPage?.seo_keywords?.twitter_site ?? "",
        twitter_title: homeContentPageData?.contentPage?.seo_keywords?.twitter_title ?? "",
        twitter_description: homeContentPageData?.contentPage?.seo_keywords?.twitter_description ?? "",
        twitter_url: homeContentPageData?.contentPage?.seo_keywords?.twitter_url ?? "",
      });
      setBannerSectionVideoUrl(homeContentPageData?.contentPage?.contentImages?.bannerVideo ?? null);
      setAboutSectionImageUrl(homeContentPageData?.contentPage?.contentImages?.aboutSectionImage ?? BannerImage);
      setWhyChooseSectionImageUrl(homeContentPageData?.contentPage?.contentImages?.whyChooseSectionImage ?? BannerImage);
      setTwitterImageUrl(homeContentPageData?.contentPage?.seo_keywords?.twitter_image ?? BannerImage);
      setOGImageUrl(homeContentPageData?.contentPage?.seo_keywords?.og_image ?? BannerImage);
      setMeetProviderSectionFirstProviderThumbnailUrl(homeContentPageData?.contentPage?.contentImages?.firstProviderThumbnail ?? BannerImage);
      setMeetProviderSectionSecondProviderThumbnailUrl(homeContentPageData?.contentPage?.contentImages?.secondProviderThumbnail ?? BannerImage);
      setMeetProviderSectionThirdProviderThumbnailUrl(homeContentPageData?.contentPage?.contentImages?.thirdProviderThumbnail ?? BannerImage);
      setMeetProviderSectionFirstProviderVideoUrl(homeContentPageData?.contentPage?.contentImages?.firstProviderVideo ?? null);
      setMeetProviderSectionSecondProviderVideoUrl(homeContentPageData?.contentPage?.contentImages?.secondProviderVideo ?? null);
      setMeetProviderSectionThirdProviderVideoUrl(homeContentPageData?.contentPage?.contentImages?.thirdProviderVideo ?? null);
    }
  }, [homeContentPageData, form]);

  const onUpdate = (values) => {
    const data = {
      ...values,

      name: 'Home Page',
      "og_image": ogImageFile ?? "",
      "twitter_image": twitterImageFile ?? "",
      "is_canonical": values.is_canonical === true ? 1 : 0,
      "contentImages[bannerVideo]": bannerSectionVideoFile ?? "",
      "contentImages[aboutSectionImage]": aboutSectionImageFile ?? "",
      "contentImages[whyChooseSectionImage]": whyChooseSectionImageFile ?? "",
      "contentImages[firstProviderThumbnail]": meetProviderSectionFirstProviderThumbnailFile ?? "",
      "contentImages[secondProviderThumbnail]": meetProviderSectionSecondProviderThumbnailFile ?? "",
      "contentImages[thirdProviderThumbnail]": meetProviderSectionThirdProviderThumbnailFile ?? "",
      "contentImages[firstProviderVideo]": meetProviderSectionFirstProviderVideoFile ?? "",
      "contentImages[secondProviderVideo]": meetProviderSectionSecondProviderVideoFile ?? "",
      "contentImages[thirdProviderVideo]": meetProviderSectionThirdProviderVideoFile ?? "",
      _method: 'PUT'
    }
    dispatch(postHomeContentPageContentRequest({ data, id: CONTENT_PAGES.HOME_PAGE }))
  };

  return (
    <>
      {homeContentPageStatus === STATUSES.LOADING ? (
        <Loading />
      ) : homeContentPageStatus === STATUSES.ERROR ? (
        <div>{homeContentPageError}</div>
      ) : (
        <HomeWrapped>
          <div className="row">
            <div className="col-lg-12">
              <Form
                layout="vertical"
                className="pt-3"
                form={form}
                onFinish={onUpdate}
              >
                {/* <div className="row">
                  <div className="col-sm-12 d-flex justify-content-between align-items-start mb-4">
                    <h3 className="font-bold" >Home</h3>
                    <SaveButton title="Update" />
                  </div>
                </div> */}
                <div className="row">
                  <div className="col-lg-9 col-12 d-flex justify-content-between align-items-start mb-4">
                    <h3 className="font-bold" >Home</h3>
                    <SaveButton title="Update" />
                  </div>
                  <div className="col-lg-9 col-12">
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Banner Section Heading"
                      name="detail[bannerSection][heading]"
                      placeText="Banner Section Heading"
                    />
                    <TextAreas
                      labelText="Banner Section Description"
                      name="detail[bannerSection][description]"
                      placeText="Banner Section Description"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Banner Section Search Button Text"
                      name="detail[bannerSection][searchButtonText]"
                      placeText="Banner Section Search Button Text"
                    />
                    <label>Banner Section Video *</label>
                    <VideoCard
                      width={400} height={300}
                      videoUrl={bannerSectionVideoUrl}
                      setVideoFile={setBannerSectionVideoFile}
                      setVideoUrl={setBannerSectionVideoUrl}
                    />
                    <br/>
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Banner Section First Counter Value"
                      name="detail[bannerSection][firstCounterValue]"
                      placeText="Banner Section First Counter Value"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Banner Section First Counter Text"
                      name="detail[bannerSection][firstCounterText]"
                      placeText="Banner Section First Counter Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Banner Section Second Counter Value"
                      name="detail[bannerSection][secondCounterValue]"
                      placeText="Banner Section Second Counter Value"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Banner Section Second Counter Text"
                      name="detail[bannerSection][secondCounterText]"
                      placeText="Banner Section Second Counter Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Banner Section Third Counter Value"
                      name="detail[bannerSection][thirdCounterValue]"
                      placeText="Banner Section Third Counter Value"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Banner Section Third Counter Text"
                      name="detail[bannerSection][thirdCounterText]"
                      placeText="Banner Section Third Counter Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Banner Section Forth Counter Value"
                      name="detail[bannerSection][forthCounterValue]"
                      placeText="Banner Section Forth Counter Value"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Banner Section Forth Counter Text"
                      name="detail[bannerSection][forthCounterText]"
                      placeText="Banner Section Forth Counter Text"
                    />
                    {/* Feature Job Section */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Feature Job Section Text"
                      name="detail[featureJobSection][text]"
                      placeText="Feature Job Section Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Feature Job Section Heading"
                      name="detail[featureJobSection][heading]"
                      placeText="Feature Job Section Heading"
                    />
                    <TextAreas
                      labelText="Feature Job Section Description"
                      name="detail[featureJobSection][description]"
                      placeText="Feature Job Section Description"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Feature Job Section Button Text"
                      name="detail[featureJobSection][buttonText]"
                      placeText="Feature Job Section Button Text"
                    />
                    {/* About Us Section */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="About Section Text"
                      name="detail[aboutSection][text]"
                      placeText="About Section Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="About Section Heading"
                      name="detail[aboutSection][heading]"
                      placeText="About Section Heading"
                    />
                    <TextAreas
                      labelText="About Section Description"
                      name="detail[aboutSection][description]"
                      placeText="About Section Description"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="About Section First Bullet Point"
                      name="detail[aboutSection][firstBulletPoint]"
                      placeText="About Section First Bullet Point"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="About Section Second Bullet Point"
                      name="detail[aboutSection][secondBulletPoint]"
                      placeText="About Section Second Bullet Point"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="About Section Third Bullet Point"
                      name="detail[aboutSection][thirdBulletPoint]"
                      placeText="About Section Third Bullet Point"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="About Section Forth Bullet Point"
                      name="detail[aboutSection][forthBulletPoint]"
                      placeText="About Section Forth Bullet Point"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="About Section Button Text"
                      name="detail[aboutSection][buttonText]"
                      placeText="About Section Button Text"
                    />
                    <BannerImageCard
                      title="About Section Image"
                      imageUrl={aboutSectionImageUrl}
                      isBlogImage={true}
                      setImageFile={setAboutSectionImageFile}
                      setImageUrl={setAboutSectionImageUrl}
                      imageText="Change Image"
                    />
                    {/* Why Choose section */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Why Choose Section Section Text"
                      name="detail[whyChooseSection][text]"
                      placeText="Why Choose Section Section Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Why Choose Section Section Heading"
                      name="detail[whyChooseSection][heading]"
                      placeText="Why Choose Section Section Heading"
                    />
                    <TextAreas
                      labelText="Why Choose Section Section Description"
                      name="detail[whyChooseSection][description]"
                      placeText="Why Choose Section Section Description"
                    />
                    
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Why Choose Section First Sub-Heading"
                      name="detail[whyChooseSection][firstSubHeading]"
                      placeText="Why Choose Section First Sub-Heading"
                    />
                    <TextAreas
                      labelText="Why Choose Section First Sub-Description"
                      name="detail[whyChooseSection][firstSubDescription]"
                      placeText="Why Choose Section First Sub-Description"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Why Choose Section Second Sub-Heading"
                      name="detail[whyChooseSection][secondSubHeading]"
                      placeText="Why Choose Section Second Sub-Heading"
                    />
                    <TextAreas
                      labelText="Why Choose Section Second Sub-Description"
                      name="detail[whyChooseSection][secondSubDescription]"
                      placeText="Why Choose Section Second Sub-Description"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Why Choose Section Third Sub-Heading"
                      name="detail[whyChooseSection][thirdSubHeading]"
                      placeText="Why Choose Section Third Sub-Heading"
                    />
                    <TextAreas
                      labelText="Why Choose Section Third Sub-Description"
                      name="detail[whyChooseSection][thirdSubDescription]"
                      placeText="Why Choose Section Third Sub-Description"
                    />
                    <BannerImageCard
                      title="Why Choose Section Image"
                      imageUrl={whyChooseSectionImageUrl}
                      isBlogImage={true}
                      setImageFile={setWhyChooseSectionImageFile}
                      setImageUrl={setWhyChooseSectionImageUrl}
                      imageText="Change Image"
                    />
                    {/* Testimonial section */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Testimonials Section Text"
                      name="detail[testimonialsSection][text]"
                      placeText="Testimonials Section Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Testimonials Section Heading"
                      name="detail[testimonialsSection][heading]"
                      placeText="Testimonials Section Heading"
                    />
                    <TextAreas
                      labelText="Testimonials Section Description"
                      name="detail[testimonialsSection][description]"
                      placeText="Testimonials Section Description"
                    />
                    {/* Meet Providers section */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Meet Our Providers Section Text"
                      name="detail[meetOurProvidersSection][text]"
                      placeText="Meet Our Providers Section Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Meet Our Providers Section Heading"
                      name="detail[meetOurProvidersSection][heading]"
                      placeText="Meet Our Providers Section Heading"
                    />
                    <TextAreas
                      labelText="Meet Our Providers Section Description"
                      name="detail[meetOurProvidersSection][description]"
                      placeText="Meet Our Providers Section Description"
                    />
                    <BannerImageCard
                      title="Meet Our Providers Section Section First Provider Thumbnail"
                      imageUrl={meetProviderSectionFirstProviderThumbnailUrl}
                      isBlogImage={true}
                      setImageFile={setMeetProviderSectionFirstProviderThumbnailFile}
                      setImageUrl={setMeetProviderSectionFirstProviderThumbnailUrl}
                      imageText="Change Image"
                    />
                    <label>Meet Our Providers Section Section First Provider Video *</label>
                    <VideoCard
                      width={400} height={300}
                      videoUrl={meetProviderSectionFirstProviderVideoUrl}
                      setVideoFile={setMeetProviderSectionFirstProviderVideoFile}
                      setVideoUrl={setMeetProviderSectionFirstProviderVideoUrl}
                    />
                    <br/>
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Meet Our Providers Section First Provider Name"
                      name="detail[meetOurProvidersSection][firstProviderName]"
                      placeText="Meet Our Providers Section First Provider Name"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Meet Our Providers Section First Provider Job Title"
                      name="detail[meetOurProvidersSection][firstProviderJobTitle]"
                      placeText="Meet Our Providers Section First Provider Job Title"
                    />
                    <BannerImageCard
                      title="Meet Our Providers Section Section Second Provider Thumbnail"
                      imageUrl={meetProviderSectionSecondProviderThumbnailUrl}
                      isBlogImage={true}
                      setImageFile={setMeetProviderSectionSecondProviderThumbnailFile}
                      setImageUrl={setMeetProviderSectionSecondProviderThumbnailUrl}
                      imageText="Change Image"
                    />
                    <label>Meet Our Providers Section Section Second Provider Video *</label>
                    <VideoCard
                      width={400} height={300}
                      videoUrl={meetProviderSectionSecondProviderVideoUrl}
                      setVideoFile={setMeetProviderSectionSecondProviderVideoFile}
                      setVideoUrl={setMeetProviderSectionSecondProviderVideoUrl}
                    />
                    <br/>
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Meet Our Providers Section Second Provider Name"
                      name="detail[meetOurProvidersSection][secondProviderName]"
                      placeText="Meet Our Providers Section Second Provider Name"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Meet Our Providers Section Second Provider Job Title"
                      name="detail[meetOurProvidersSection][secondProviderJobTitle]"
                      placeText="Meet Our Providers Section Second Provider Job Title"
                    />
                    <BannerImageCard
                      title="Meet Our Providers Section Section Third Provider Thumbnail"
                      imageUrl={meetProviderSectionThirdProviderThumbnailUrl}
                      isBlogImage={true}
                      setImageFile={setMeetProviderSectionThirdProviderThumbnailFile}
                      setImageUrl={setMeetProviderSectionThirdProviderThumbnailUrl}
                      imageText="Change Image"
                    />
                    <label>Meet Our Providers Section Section Third Provider Video *</label>
                    <VideoCard
                      width={400} height={300}
                      videoUrl={meetProviderSectionThirdProviderVideoUrl}
                      setVideoFile={setMeetProviderSectionThirdProviderVideoFile}
                      setVideoUrl={setMeetProviderSectionThirdProviderVideoUrl}
                    />
                    <br/>
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Meet Our Providers Section Third Provider Name"
                      name="detail[meetOurProvidersSection][thirdProviderName]"
                      placeText="Meet Our Providers Section Third Provider Name"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Meet Our Providers Section Third Provider Job Title"
                      name="detail[meetOurProvidersSection][thirdProviderJobTitle]"
                      placeText="Meet Our Providers Section Third Provider Job Title"
                    />
                  </div>
                  <SeoSection ogImageUrl={ogImageUrl}
                    setOGImageFile={setOGImageFile}
                    setOGImageUrl={setOGImageUrl}
                    twitterImageUrl={twitterImageUrl}
                    setTwitterImageFile={setTwitterImageFile}
                    setTwitterImageUrl={setTwitterImageUrl} />
                </div>
              </Form>
            </div>
          </div>
        </HomeWrapped>
      )}
    </>
  );
};

export default HomeContentPage;
