import { Modal } from "antd";
import React from "react";
import profile from "../../assets/images/profile.png";
import upload from "../../assets/images/upload.png";


const JobApplicantDetailModal = ({
    isModalOpen,
    setIsModalOpen,
    modalData,
}) => {

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <Modal
                className="modal-styles"
                open={isModalOpen}
                onCancel={handleCancel}
                footer={null}
                // width={600}
                maskClosable={false}
                centered={true}
                title={false}
            >
                <div className="row">
                    <div className="col-md-12">
                        <h5 className="table-heading mb-0 ">Job Applicant</h5>
                    </div>
                </div>
                <div className="modal-content">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="d-flex align-items-center user-profile">
                                <div>
                                    <img src={profile} alt="" className="img-fluid" />
                                </div>
                                <div className="ms-3">
                                    <h3 className="mb-0"> {modalData?.first_name ?? ""}&nbsp;
                                        {modalData?.last_name ?? ""}</h3>
                                    <p className="mb-0">{modalData?.job?.discipline?.name ?? ""}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="Applied-box mt-3 mt-lg-0 d-sm-flex align-items-center justify-content-between">
                                <div className="">
                                    <h6>{modalData?.job?.discipline?.name ?? ""}</h6>
                                    <div className="d-sm-flex align-items-center">
                                        <span>Job Location:</span>
                                        <p className="mb-0 ms-2">{modalData?.job?.location?.name ?? ""}</p>
                                    </div>
                                </div>
                                <div>
                                    <button className="applied-btn mt-2 mt-sm-0">Applied</button>
                                </div>
                            </div>
                        </div>
                        <div className="mt-3">
                            <div className="d-sm-flex ">
                                <div className="col-sm-6">
                                    <div className="user-data">
                                        <h6>First Name</h6>
                                        <p>{modalData?.first_name ?? ""}</p>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="user-data">
                                        <h6>Last Name</h6>
                                        <p>{modalData?.last_name ?? ""}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="d-sm-flex ">
                                <div className="col-sm-6">
                                    <div className="user-data">
                                        <h6>Email Address</h6>
                                        <p>{modalData?.email ?? ""}</p>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="user-data">
                                        <h6>Phone Number</h6>
                                        <p>{modalData?.phone ?? ""}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="d-sm-flex ">
                                <div className="col-sm-6">
                                    <div className="user-data">
                                        <h6>Discipline</h6>
                                        <p>{modalData?.job?.discipline?.name ?? ""}</p>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="user-data">
                                        <h6>Applied Date</h6>
                                        <p>{modalData?.updated_at ?? ""}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="d-sm-flex ">
                                <div className="col-sm-6">
                                    <div className="user-data">
                                        <h6>Resume</h6>
                                        <div className="download-icon">
                                            <img src={upload} alt="" className="img-fluid" onClick={() => { window.open(modalData?.resume ?? "", '_blank', 'noreferrer') }} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="user-data">
                                        <h6>Licenses</h6>
                                        {(modalData?.job_apply_licenses ?? []).map((license) => (
                                            <div className="d-flex align-items-center justify-content-between mb-2" key={license?.user_license?.id}>
                                                <p className="mb-0" style={{ maxWidth: '330px', overflow: 'hidden', }}>{license?.user_license?.title}</p>
                                                <div className="download-icon">
                                                    <img src={upload} alt="" className="img-fluid" onClick={() => { window.open(license?.user_license?.license ?? "", '_blank', 'noreferrer') }} />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};
export default JobApplicantDetailModal;
