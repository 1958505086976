import { Button } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { getAutoCompleteDetailsByIdRequest } from "../../redux/autoCompleteApprovalRequestSlice";
import AutoCompleteModal from "../modal/AutoCompleteModal";

const AutoCompleteRequestCard = ({ autoCompleteList, search, pageNumber }) => {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [itemData, setItemData] = useState();

  const showModal = (item) => {
    dispatch(getAutoCompleteDetailsByIdRequest(item.id));
    setItemData(item);
    setIsModalOpen(true);
  };
  return (
    <> 
      {autoCompleteList.map((item, index) => (
        <div className="style-card">
          <div className="card-style-sub">
            <div className="align-items-center">
              <div className="avatar-info">
                <h5 className="table-heading">{item?.value ?? ""}</h5>
                <p className="table-sub-heading">{item?.model_name ?? ""}</p>
              </div>
            </div>

            <div className="card_left">
              <p style={{ paddingRight: "50px" }}>
                <span className="time-style">{item?.created_at ?? ""}</span>
              </p>
              <span className="pl-6">
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    showModal(item);
                  }}
                >
                  <span className="seeker-color view-style"> View </span>
                </span>
              </span>
            </div>
          </div>
        </div>
      ))}
      <AutoCompleteModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        autoCompleteData={itemData}
        search={search}
        pageNumber={pageNumber}
      />
    </>
  );
};
export default AutoCompleteRequestCard;
