import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { successNotification, errorNotification } from "../components/notifications/alertNotification";
import { REQUEST_METHOD, STATUSES } from "../config/constants/defaultValues";
import { AUTO_COMPLETE_ACCEPT_REJECT_API, AUTO_COMPLETE_ALL_READY_USED_API, AUTO_COMPLETE_APPROVAL_API } from "../config/constants/endPoints";
import { apiService } from "../config/services/api.service";


export const getAutoCompleteApprovalRequest = createAsyncThunk(
    "autoCompleteApprovalRequest/getAutoCompleteApprovalRequest",
    async ({ generalSearch, page }, { rejectWithValue }) => {
        try {
            const response = await apiService(
                AUTO_COMPLETE_APPROVAL_API,
                REQUEST_METHOD.POST,
                {
                    "sort": { "field": "id", "sort": "asc" },
                    "pagination": { "page": page ?? 1, "per_page": "10" },
                    "query": { "status": 0, "generalSearch": generalSearch ?? "" }
                },
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);

export const autoCompleteRejectAndAcceptRequest = createAsyncThunk(
    "autoCompleteApprovalRequest/autoCompleteRejectRequest",
    async (values, { rejectWithValue }) => {
        // try {
        //     const response = await apiService(
        //         AUTO_COMPLETE_ACCEPT_REJECT_API,
        //         REQUEST_METHOD.POST,
        //         values,
        //         true,
        //     );
        //     if (response.status === 200) {
        //         successNotification(response.data.message)
        //         return response.data.data;
        //     } else {
        //         return errorNotification(Object.values(response.data.errors));
        //     }
        // } catch (error) {
        //     errorNotification("Something went wrong")
        //     return rejectWithValue("Something went wrong");
        // }
    }
);
export const getAutoCompleteDetailsByIdRequest = createAsyncThunk(
    "autoCompleteApprovalRequest/getAutoCompleteDetailsByIdRequest",
    async (autoCompleteID, { rejectWithValue }) => {
        try {
            const response = await apiService(
                AUTO_COMPLETE_ALL_READY_USED_API + autoCompleteID,
                REQUEST_METHOD.GET,
                {},
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);

const AutoCompleteApprovalSlice = createSlice({
    name: "autoCompleteApprovalRequest",
    initialState: {
        autoCompleteData: null,
        status: STATUSES.IDLE,
        error: null,
        autoCompleteDetails: null,
        autoCompleteDetailsStatus: STATUSES.IDLE,
        autoCompleteDetailsError: null,
    },
    extraReducers: {
        [getAutoCompleteApprovalRequest.pending]: (state, action) => {
            state.status = STATUSES.LOADING;
        },
        [getAutoCompleteApprovalRequest.fulfilled]: (state, action) => {

            state.status = STATUSES.IDLE;
            state.autoCompleteData = action.payload;
        },
        [getAutoCompleteApprovalRequest.rejected]: (state, action) => {
            state.status = STATUSES.ERROR;
            state.error = action.payload;
        },
        [getAutoCompleteDetailsByIdRequest.pending]: (state, action) => {
            state.autoCompleteDetailsStatus = STATUSES.LOADING;

        },
        [getAutoCompleteDetailsByIdRequest.fulfilled]: (state, action) => {
            state.autoCompleteDetailsStatus = STATUSES.IDLE;
            state.autoCompleteDetails = action.payload;


        },
        [getAutoCompleteDetailsByIdRequest.rejected]: (state, action) => {
            state.autoCompleteDetailsStatus = STATUSES.ERROR;
            state.autoCompleteDetailsError = action.payload;
        },
    },
});
export default AutoCompleteApprovalSlice.reducer;

